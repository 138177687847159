.BG_imge {
    background-image: url('../assest/bgbuilding.webp');
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: white;
    /* filter: brightness(60%); */
}

.BG_imge img {
    width: 80px;
    border-radius: 6px;
}

.BG_imge h1 {
    font-size: 80px;
    font-weight: bold;
    letter-spacing: 2px;
    margin-top: 30px;
    text-shadow: 0 0 24px black, 0 0 24px black;
}

.BG_imge h6 {
    font-size: 40px;
    font-weight: 400;
    letter-spacing: 2px;
    text-shadow: 0 0 24px black, 0 0 24px black;

}

.BG_imge .address {
    display: flex;
    justify-content: space-around;
    margin-top: 100px;
    width: 100%;

}

.BG_imge .address h2 {
    font-size: 40px;
    font-weight: bold;
    letter-spacing: 2px;
    text-shadow: 0 0 24px black, 0 0 24px black;
    margin-bottom: 0;

}

.BG_imge .address p {
    font-size: 30px;
    margin: 0;
    text-shadow: 0 0 24px black, 0 0 24px black;


}
.textfantasy{
    text-align: center;

}
.textfantasy h2 {
    color: #4db5ff;
    font-weight: bold;
    font-size: 40px;
}
.textfantasy h3 {
    margin: 10px 60px;
}

.textfantasy .details_work {
    display: flex;
    justify-content: space-evenly;
    align-items: center;

}

.textfantasy .details_work div {
    width: 260px;
    text-align: center;
    /* font-size: 40px; */
}

.textfantasy .details_work div img {
    width: 100px;
}

.textfantasy .details_work div h1 {
    margin: 0;
}

.textfantasy .details_work div p {
    margin: 0;
    font-weight: bold;
    font-size: 20px;
    padding: 0;
}

.our_service {
    display: flex;
    justify-content: space-around;
    align-items: center;
    margin: 50px;
}

.our_service p {
    margin: 20px 0;
    font-size: 18px;
    letter-spacing: 2px;
}

.our_service div {
    width: 300px;
}

.our_service div h1 {
    font-weight: bold;

}

.our_service div h2 {
    font-weight: bold;
    color: #4db5ff;
}

.our_service div .imgicondiv img {
    width: 50px;
}

.our_service div .imgicondiv {
    display: flex;
    align-items: center;
}

.our_service div .imgicondiv h3 {
    font-weight: bold;
    font-size: 22px;
    margin-left: 10px;
}

.bgimgecontact {
    background-image: url('../assest/bgimg.webp');
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 500px;
    color: white;
    /* filter: brightness(70%); */
}

.bgimgecontact img {
    width: 100px;
    border-radius: 6px;
}

.bgimgecontact h1 {
    text-shadow: 20px 10px 40px black, 20px 10px 40px black;
    margin: 50px 0;
    font-size: 46px;
    font-weight: bold;
    letter-spacing: 2px;

}

.bgimgecontact p {
    text-shadow: 20px 10px 40px black, 20px 10px 40px black;
    font-size: 28px;
}

.ContactUs {
    padding-left: 10%;
}

.ContactUs .h1tag {
    font-weight: bold;
    font-size: 52px;
    letter-spacing: 2px;
    margin-bottom: 40px;
}

.ContactUs .h1tag span {
    color: #4db5ff;
}

.contactdiv {
    display: flex;
    align-items: center;
    margin: 20px 0;

}

.contactdiv .icondiv {
    margin-right: 15px;
}

.contactdiv .icondiv p {
    background-color: #4db5ff;
    color: white;
    border-radius: 50%;
}

.contactdiv h6 {
    margin: 0;
    font-weight: bold;
    font-size: 18px;
}

.contactdiv p {
    margin: 0;
    font-size: 18px;

}

.homecoverimgdiv img {
    width: 100%;
    height: 380px;
    object-fit: cover;
    margin-bottom: 20px;
}

.thankydiv img {
    width: 100%;
    height: 100vh;
    object-fit: cover;
    margin-bottom: 0px;
    filter: brightness(20%);
}
.thankydiv .thnks{
    color: white;
    margin-top: -25%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    filter: brightness(100%);

}
.thankydiv .thnks h1{
    font-weight: bold;
    font-size: 70px;
    letter-spacing: 2px;
}
.thankydiv .thnks p{
    font-weight: bold;
    font-size: 20px;
    letter-spacing: 2px;
    margin-top: 20px;
    text-align: center;
}

@media screen and (max-width:767px) {

    .BG_imge {
        height: 250px;
    }

    .BG_imge img {
        width: 60px;
    }

    .BG_imge h1 {
        font-size: 25px;
        margin-top: 20px;
    }

    .BG_imge h6 {
        font-size: 16px;
    }

    .BG_imge .address {
        margin-top: 10px;
    }

    .BG_imge .address h2 {
        font-size: 16px;
        letter-spacing: 0px;
    }

    .BG_imge .address p {
        font-size: 12px;
        letter-spacing: 0px;
    }

    .textfantasy h2 {
        font-size: 24px;
    }

    .textfantasy h3 {
        font-size: 20px;
    }

    .textfantasy .details_work {
        padding: 0;
        display: flex;
        justify-content: space-between;

    }

    .textfantasy .details_work div {
        width: 100%;
        padding: 5px;
        text-align: center;
        /* font-size: 40px; */
    }

    .textfantasy .details_work div img {
        width: 80px;
    }

    .textfantasy .details_work div h1 {
        margin: 0;
        font-size: 25px;
    }

    .textfantasy .details_work div p {
        font-size: 16px;
    }


    .fantasy .textfantasy .buttonclick {
        color: white;
        border: 1px solid #4db5ff;
        background-color: #4db5ff;
        padding: 10px 30px;
        font-weight: bold;
        letter-spacing: 2px;
        font-size: 18px;
        margin: 20px 0;
    }

    .fantasy .textfantasy .buttonclick:hover {
        background-color: #4db5ff;
        color: white;
    }


    .our_service {
        display: block;
        margin: 50px 30px;
    }

    .bgimgecontact {
        height: 250px;
    }

    .bgimgecontact img {
        width: 70px;
    }

    .bgimgecontact h1 {
        margin: 20px 0;
        font-size: 26px;

    }

    .bgimgecontact p {
        font-size: 24px;
    }

    .ContactUs {
        padding: 10%;
    }

    .ContactUs .h1tag {
        font-size: 32px;
    }
    .homecoverimgdiv img {
        height: 260px;
    }

    .thankydiv img {
        height: 260px;
    }
    .thankydiv .thnks{
        margin-top: -42%;
    
    }
    .thankydiv .thnks h1{
        font-size: 26px;
    }
    .thankydiv .thnks p{
        font-size: 14px;
        margin-top: 20px;
        padding: 0 30px;
    }

}