.focusgol{
    margin: 40px;

}
.focusgol h4{
     font-weight: bold;
     color: rgb(58, 56, 56);
     letter-spacing: 0.3px;
}
.vill_farm {
    display: flex;
    align-items: center;
    margin: 15px 0px;
}

.vill_farm div {
    margin: 5px 10px;

}

.vill_farm div img {
    width: 70px;
    height: 70px;
    border-radius: 35px;
}

.vill_farm div p {
    font-size: 18px;
    letter-spacing: 0.5px;
    margin-bottom: 0;
}

.focusgol p{
    color: rgb(58, 56, 56);
    letter-spacing: 0.3px;
    font-size: 18px;
}

@media screen and (max-width:767px) {
   
    .focusgol{
        margin: 40px 20px;
    
    }
    .vill_farm {
        display: block;
        margin: 10px 0;

    }

    .vill_farm div {
        margin: 5px 0px;
        text-align:left;
    }
}