.testimonial-box {
    background: #f9f9f9;
    margin-bottom: 30px;
}

.testimonial-box .div-box {
    display: block;
    position: relative;
    padding: 20px 0;
}

.testimonial-box h1 {
    text-align: center;
    font-size: 46px;
    line-height: 40px;
    font-weight: 900;
}

.comnt-box{
    display: flex;
    box-shadow: 1px 1px 4px rgb(187, 201, 223);
    padding:30px 40px;
    margin: 10px 50px;
    border-radius: 8px;
}
.comnt-box div img{
    width: 100px;
    height: 100px;
    border-radius: 50px;
}
.comnt-box .detail-box {
    margin-left: 30px;
}
.comnt-box .detail-box .person-info{
    display: flex;
    align-items: center;
}
.comnt-box .detail-box .person-info h4{
    color: #595aff;
    margin-right: 18px;
}
.comnt-box .detail-box p{
    margin-bottom:6px;
}

@media screen and (max-width:567px) {
    .testimonial-box .div-box {
        padding: 20px 0;
    }
    
    .testimonial-box h1 {
        font-size: 26px;
        line-height: 0px;
        font-weight: 900;
        text-transform: uppercase;
    }
   .comnt-box{
    display: block;
    text-align: center;
    padding: 20px;
    margin: 10px;

   }
   .comnt-box .detail-box {
    margin: 0;
   }
   .comnt-box .detail-box .person-info{
    display: block;
   }
   .comnt-box .detail-box .person-info h4{
    margin-top: 20px;
   }
   
}