.custom_solarimg img {
    height: 32rem;
    width: 100%;
    filter: brightness(0.5);
    object-fit: cover;
}


.record_topdiv {
    margin-bottom: 6%;
}

.record_topdiv img {
    width: 100%;
    height: 100vh;
    object-fit: cover;
    margin-bottom: 0px;
    filter: brightness(90%);
}

.record_topdiv .record_head {
    color: white;
    margin-top: -25%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    filter: brightness(100%);
    width: 600px;

}

.record_topdiv .record_head h1 {
    font-weight: bold;
    font-size: 30px;
    letter-spacing: 2px;
    margin: 0px 10%;

}

.record_topdiv .record_head p {
    font-weight: 400;
    font-size: 18px;
    letter-spacing: 1px;
    margin: 20px 10%;
}

.record_topdiv button {
    background: linear-gradient(to right, #8c5fff, #f17bfe);
    color: white;
    text-transform: capitalize;
    font-size: 20px;
    padding: 12px 30px;
    margin-top: 20px;
}

.contactusform label {
    font-size: 14px;
    margin-left: 4px;
}

.contactusform select {
    height: 50px;
}


.trailblazer_topdiv {
    margin-bottom: 15%;
}

.trailblazer_topdiv img {
    width: 100%;
    height: 100vh;
    object-fit: cover;
    margin-bottom: 0px;
    filter: brightness(40%);
}

.trailblazer_topdiv .trailblazer_head {
    color: white;
    margin-top: -32%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    filter: brightness(100%);

}

.trailblazer_topdiv .trailblazer_head h1 {
    font-weight: bold;
    font-size: 40px;
    letter-spacing: 2px;
}

.trailblazer_topdiv .trailblazer_head p {
    font-weight: bold;
    font-size: 20px;
    letter-spacing: 2px;
    margin: 20px 10%;
    text-align: center;
}

.trailblazer_topdiv button {
    background: linear-gradient(to right, #8c5fff, #f17bfe);
    color: white;
    text-transform: capitalize;
    font-size: 16px;
    padding: 8px 30px;
    margin-top: 40px;
}

.getstarteddiv p {
    margin: 30px 100px;
    padding: 0 100px;
    text-align: center;
    font-size: 16px;
}

.collaboratediv {
    padding: 50px;
}

.main_Contactsolar {
    background-color: #fff;
    border-radius: 6px;
    padding: 50px;
    margin-right: 40px;
}

@media screen and (max-width:767px) {
    .custom_solarimg img {
        height: 20rem;
    }

    .record_topdiv {
        margin-bottom: 12%;
    }

    .record_topdiv img {
        height: 400px;
    }

    .record_topdiv .record_head {
        margin-top: -72%;
        width: 90%;

    }

    .record_topdiv .record_head h1 {
        font-size: 24px;
    }

    .record_topdiv .record_head p {
        font-size: 14px;
        margin: 10px;
        padding: 0 10px;
    }

    .record_topdiv button {
        font-size: 14px;
        padding: 8px 30px;
    }

    .trailblazer_topdiv {
        margin-bottom: 19%;
    }

    .trailblazer_topdiv img {
        height: 500px;
    }

    .trailblazer_topdiv .trailblazer_head {
        margin-top: -100%;

    }

    .trailblazer_topdiv .trailblazer_head h1 {
        font-size: 22px;
    }

    .trailblazer_topdiv .trailblazer_head p {
        font-size: 12px;
        text-align: justify;
        font-weight: 400;
        margin: 10px;
        padding: 0 10px;
    }

    .trailblazer_topdiv button {
        margin-top: 10px;
    }

    .getstarteddiv p {
        margin: 30px;
        padding: 0;
        text-align: justify;
    }

    .collaboratediv {
        padding: 30px 10px;
    }

    .main_Contactsolar {
        padding: 20px 10px;
        margin: 5px;
    }
}