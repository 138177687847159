.RealEstateFooter{
    background-color:#4db5ff;
    padding: 10px 0 2px 0;
}
.boxmain{
    display: flex;
    padding:20px 0 30px 0;
    justify-content: space-around;
}
.listBox_first{
    color: white;
    width: 500px;
    text-align: center;
}
.listBox{
    color: white;
    width: 300px;
}

.ListItemText span{
    font-size: 21px;
}
.ListItemText .imglogo{
    width: 100px;
    border-radius: 8px !important;
    object-fit: contain;
}
.listBox_first .ListItemText_heading span{ 
    font-size: 24px !important;
    font-weight: bold;
    letter-spacing: 2px;
    margin: 30px 0;
}

.listBox_first .ListItemText button {
    background-color: white;
    border: 1px solid #4db5ff;
    color: #4db5ff;
    padding:10px 30px;
    font-weight: bold;
    letter-spacing: 2px;
    font-size: 22px;
    margin: 20px 0;
}
.listBox_first .ListItemText button:hover{
    background-color: #4d65ff;
    color: white;
}

.ListItemText .address{
    font-size: 26px;
    margin: 30px 0;
}
.ListItemText .address h5{
    font-size: 26px;
    margin: 0 0 4px 0;
    font-weight: bold;
}
.iconsocial{
    font-size: 40px;
    margin-right: 7px;
}

@media only screen and (max-width: 767px) {
    .boxmain{
        display: block;
        padding:10px;
    }
    .listBox_first{
        width: 100%;
    }
    .listBox{
        width: 100%
    }
    
    .ListItemText .imglogo{
        width: 70px;
    }
    .listBox_first .ListItemText_heading span{ 
        font-size: 18px !important;
    }
    
}
