.SecondSlider_Restaurant {
    padding: 10px 5px;
}

.SecondSlider_Restaurant h1 {
    font-size: 24px;
    text-align: start;
    text-transform: uppercase;
}

.Res_Second_Slider{
    margin: 20px;
    width: 90% !important;
    text-align: justify;

}
.Res_Second_Slider img {
    height: 40vh;
    width: 100%;
    object-fit: contain;
    border-radius: 4px;
}
.Res_Second_Slider h4 {
    margin: 20px 0 10px 0;
}
.Res_Second_Slider .info {
    display: flex;
    justify-content: space-between;
    margin: 20px 0;
}

.slick-prev,
.slick-next {
    background-color: rgb(0, 0, 0) !important;
    color: black !important;
    width: 40px !important;
    height: 50px !important;
    /* border-radius: 10px !important; */
    z-index: 66 !important;
    box-shadow: 2px 1px 6px rgb(125, 85, 85) !important;
}

.slick-prev {
    border-radius: 0 6px 6px 0 !important;
    margin: 0 25px;
}

.slick-next {
    border-radius: 6px 0 0 6px !important;
    margin: 0 26px;
}

@media screen and (max-width:767px) {
    .SecondSlider_Restaurant {
        padding: 0;
        width: 100%;
    }

    .SecondSlider_Restaurant h1 {
        font-size: 18px;
    }

    .Res_Second_Slider img {
        height: 250px;
        width: 100%;
        margin-left: 1%;
    }

    .slick-prev,
    .slick-next {
        width: 24px !important;
        height: 30px !important;
    }

    .slick-prev {
        border-radius: 0 6px 6px 0 !important;
        margin: 0 15px;
    }

    .slick-next {
        border-radius: 6px 0 0 6px !important;
        margin: 0 15px;
    }
}

@media screen and (min-width: 768px) and (max-width: 992px) {
    .SecondSlider_Restaurant {
        padding: 10px 5px;
    }

    .Res_Second_Slider img {
        height: 250px;
        width: 200px;
        object-fit: contain;
        border-radius: 4px;
    }
}