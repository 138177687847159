.Import_Export_topdiv {
    margin-bottom: 15%;
}

.Import_Export_topdiv img {
    width: 100%;
    height: 100vh;
    object-fit: cover;
    margin-bottom: 0px;
    filter: brightness(40%);
}

.Import_Export_topdiv .Import_Export_head {
    color: white;
    margin-top: -35%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    filter: brightness(100%);

}

.Import_Export_topdiv .Import_Export_head h1 {
    font-weight: bold;
    font-size: 70px;
    letter-spacing: 2px;
    text-transform: uppercase;
    text-align: center;
}

.Import_Export_topdiv .Import_Export_head p {
    font-weight: bold;
    font-size: 20px;
    letter-spacing: 2px;
    margin: 20px 10%;
    text-align: center;
}

.Import_exportproject_div {
    background-color: rgb(235, 233, 233);
    color: #1c1b1b;
    padding: 40px 5px;
    text-align: center;
}
.Import_exportproject_div h3{
    margin-top: 50px;
    padding-top: 50px;
    padding-bottom: 30px;
}

.importexport_slide {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}


.importexport_slide a {
    text-decoration: none;
}

.importexport_life_Slider {
    margin: 20px;
    width: 280px;
    border: 5px solid white;
    border-radius: 4px;
    background-color: white;
    padding-bottom: 0px;
    color: black;
    box-shadow: 0 0.25rem 1.125rem rgba(75, 70, 92, 0.1);

}

.importexport_life_Slider img {
    height: 30vh;
    width: 90%;
    margin:10px auto;
    object-fit: cover;
    border-radius: 4px;
}

.importexport_life_Slider h4 {
    margin: 20px 20px 0 20px;
    font-weight: 700;
    font-size: 18px;
  
}

.Import_exportproject_div .explorebtn {
    background: linear-gradient(to right, #362366, #4a1050);
    color: white;
    text-transform: capitalize;
    font-size: 16px;
    padding: 8px 30px;
    margin-top: 20px;
    width: 100%;
}


.GiftsCrafts {
    background-color: #eee1f5;
    padding: 40px 5px;
    text-align: center;
}

.GiftsCrafts_slide {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}


.GiftsCrafts_slide a {
    text-decoration: none;
}


.GiftsCrafts_life_Slider {
    margin: 20px;
    width: 260px;
    text-align: justify;
    border: 5px solid white;
    border-radius: 40px;
    background: linear-gradient(to right, #362366, #4a1050);
    color: white;
    padding-bottom: 10px;
    box-shadow: 0 0.25rem 1.125rem rgba(75, 70, 92, 0.1);

}

.GiftsCrafts_life_Slider img {
    height: 30vh;
    width: 100%;
    object-fit: cover;
    border-radius: 35px 35px 0 0;
}

.GiftsCrafts_life_Slider h4 {
    margin: 0;
    padding: 10px 0;
    font-weight: 700;
    font-size: 20px;
    text-align: center;
   
}


@media screen and (max-width:767px) {
    .Import_Export_topdiv img {
        height: 400px;
    }

    .Import_Export_topdiv .Import_Export_head {
        margin-top: -62%;

    }

    .Import_Export_topdiv .Import_Export_head h1 {
        font-size: 26px;
    }

    .Import_Export_topdiv .Import_Export_head p {
        font-size: 10px;
        margin-top: 10px;
        padding: 0 10px;
    }

    .Import_exportproject_div h3{
        margin-top: 10px;
        padding-top: 30px;
        padding-bottom: 30px;
    }

    .GiftsCrafts_slide {
        padding: 10px 10px;
        width: 100%;
    }

    .GiftsCrafts_slide h1 {
        font-size: 18px;
    }

    .GiftsCrafts_life_Slider img {
        height: 250px;
        width: 100% !important;
        /* margin-left: 10%; */
    }
}



















