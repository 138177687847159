.albumContainer {
    padding: 3% 5%;
    text-align: center;
    position: relative;
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    justify-content: center;
    align-items: center;
    position: relative;
    background-color: rgb(235, 233, 233);
    color: #1c1b1b;
  }
  .albumContainer::before {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: rgb(235, 233, 233);
    color: #1c1b1b;
    background-size: contain;
    background-repeat: repeat;
    opacity: 0.2;
    z-index: -44;
}
  .albumContainer .react-photo-gallery--gallery div{
    align-items: center;
    justify-content: center;
    display: flex;
  }
  
  .albumContainer .react-photo-gallery--gallery div img {
    object-fit: cover;
    width: 300px;
    height: 200px;
    margin: 5px !important;
  }
  
  .gallerytitle {
    margin-top: 1%;
    margin-bottom: 4%;
    font-size: 28px;
    font-weight: bold;
    text-transform: uppercase;
  }
  
  .galleryItems {
    width: 50%;
    height: 50%;
  }
  
  .react-images__footer__caption{
    font-size: 32px;
    font-weight: bold;
    text-shadow: 0 0 10px black;
  } 
  .gallery-photo-container{
   display: block !important; 
   /* border: 1px solid gray; */
   box-shadow: 0 0.25rem 1.125rem rgba(75, 70, 92, 0.1);
   background-color: white;
   border-radius: 4px;
   margin: 10px;
   transition: all 0.5s;
   overflow: hidden;
  }
  .gallery-photo-container:hover{
    transform: translate(0,5px);
    box-shadow: 0 0 20px white, 0 0 20px white;
  }
  .gallery-photo-container .gallery-photo-caption{
    font-size: 22px;
    font-weight: bold;
    margin-bottom: 15px;
    text-transform: uppercase;
  }
  
  .gallery-photo-container .gallery-photo{
    object-fit: cover;
    width: 300px ;
    height: 200px;
    transition: all 0.5s;

  }
  .gallery-photo-container .gallery-photo:hover{
    transform: scaleX(1.2) scaleY(1.1);
    object-fit: cover;
  }
  
  
  @media only screen and (max-width: 767px) {
  
    .gallerytitle {
      font-size: 22px;
      margin-bottom: 8%;
      margin-top: 4%;
    }
    .albumContainer .react-photo-gallery--gallery div{
      align-items: center;
      justify-content: center;
      display: flex;
    }
  
    /* Individual item */
    .bm-item {
      display: inline-block;
  
      /* Our sidebar item styling */
      text-decoration: none;
      margin-bottom: 10px;
      color: #d1d1d1;
      transition: color 0.2s;
    }
  
    /* Change color on hover */
    .bm-item:hover {
      color: white;
    }
  
    /* The rest copied directly from react-burger-menu docs */
  
    /* Position and sizing of burger button */
    .bm-burger-button {
      position: absolute;
      width: 36px;
      height: 30px;
      right: 32px;
      top: 26px;
    }
  
    /* Color/shape of burger icon bars */
    .bm-burger-bars {
      background: #373a47;
    }
  
    /* Position and sizing of clickable cross button */
    .bm-cross-button {
      height: 24px;
      width: 24px;
    }
  
    /* Color/shape of close button cross */
    .bm-cross {
      background: #bdc3c7;
    }
  
    /* General sidebar styles */
    .bm-menu {
      background: #373a47;
      padding: 2.5em 1.5em 0;
      font-size: 1.15em;
    }
  
    /* Morph shape necessary with bubble or elastic */
    .bm-morph-shape {
      fill: #373a47;
    }
  
    /* Wrapper for item list */
    .bm-item-list {
      color: #b8b7ad;
    }
  
    /* Styling of overlay */
    .bm-overlay {
      background: rgba(0, 0, 0, 0.3);
    }
  }