.Partners_head{
    margin: 40px;
}

.Partners_head p{
     font-size: 18px;
     letter-spacing: 1px;
}
.Partners_imgdiv{
    display: flex;
    flex-wrap: wrap;
}
.Partners_imgdiv div{
    box-shadow:  0 0.25rem 1.125rem rgba(75, 70, 92, 0.1);
    margin: 20px;
    padding: 20px;

}

.Partners_imgdiv div img{
    width: 200px;
}


@media only screen and (max-width: 767px) {
    .Partners_head{
        margin: 40px 20px;
        text-align: justify;
    }
}