.internationalschool_slide {
    padding: 10px 5px;
}

.internationalschool_slide a{
    text-decoration: none;
}

.school_life_Slider{
    margin: 20px;
    width: 90% !important;
    text-align: justify;
    border: 1px solid lightgray;
    border-radius: 4px;
    background-color: white;
    color: black;

}
.school_life_Slider img {
    height: 40vh;
    width: 100%;
    object-fit: cover;
    border-radius: 4px 4px 0 0;
}
.school_life_Slider h4 {
    margin: 20px 20px 0 20px;
    font-weight: 700;
    font-size: 18px;
}
.school_life_Slider p{
    margin:10px 20px 10px 20px;
    font-size: 16px;

}

.slick-prev,
.slick-next {
    background-color: rgb(0, 0, 0) !important;
    color: black !important;
    width: 40px !important;
    height: 50px !important;
    /* border-radius: 10px !important; */
    z-index: 66 !important;
    box-shadow: 2px 1px 6px rgb(125, 85, 85) !important;
}

.slick-prev {
    border-radius: 0 6px 6px 0 !important;
    margin: 0 25px;
}

.slick-next {
    border-radius: 6px 0 0 6px !important;
    margin: 0 26px;
}

@media screen and (max-width:767px) {
    .internationalschool_slide {
        padding: 10px 10px;
        width: 100%;
    }

    .internationalschool_slide h1 {
        font-size: 18px;
    }

    .school_life_Slider img {
        height: 250px;
        width: 100% !important;
        /* margin-left: 10%; */
    }

    .slick-prev,
    .slick-next {
        width: 24px !important;
        height: 30px !important;
    }

    .slick-prev {
        border-radius: 0 6px 6px 0 !important;
        margin: 0 15px;
    }

    .slick-next {
        border-radius: 6px 0 0 6px !important;
        margin: 0 15px;
    }
}

@media screen and (min-width: 768px) and (max-width: 992px) {
    .internationalschool_slide {
        padding: 10px 5px;
    }

    .school_life_Slider img {
        height: 250px;
        width: 200px;
        object-fit: contain;
        border-radius: 4px;
    }
}