
.ngo_blog {
    width: 300px;
    margin: 20px 10px;
    border-radius: 6px;
    box-shadow:  0 0.25rem 1.125rem rgba(75, 70, 92, 0.1);
}
.ngo_blog div img {
    width: 96%;
    height: 180px;
    object-fit: cover;
    border-radius: 3%;
    margin: 20px 0;

}
.ngo_blog div a {
    text-decoration: none;
}
.ngo_blog div h5 {
    font-size: 16px;
    font-weight: 500;
    padding:20px 0;
    color: rgb(43, 41, 41);
}

@media only screen and (max-width: 767px) {
    .ngo_blog {
        width: 90%;
        margin: 20px 10px;
        border-radius: 6px;
        box-shadow:  0 0.25rem 1.125rem rgba(75, 70, 92, 0.1);
    }
    .ngo_blog div img {
        width: 96%;
        height: 180px;
        object-fit: cover;
        border-radius: 3%;
        margin: 20px 0;
    
    }
}

