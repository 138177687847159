.container .ptagg{
    text-align: justify;
    font-size: 18px;
    margin: 30px 0;
}
.Aboutuspage{
    background-color: #f9f4f4;
    padding: 30px;
}
.About_Restaurant{
    display: flex; 
    justify-content: space-between;
}
.About_Restaurant .About_detail{
    width: 300px;
    
}

.About_Restaurant .About_detail div h4 {
    margin: 0px 0 4px 0;
    font-size: 14px;
    font-weight: 500;

}
.About_Restaurant .About_detail div p{
    font-size: 13px;
    color: rgb(36, 34, 34);
    text-transform: capitalize;
    margin: 8px 0 0 4px;
}

.About_Restaurant .About_detail{
    display: flex;
    padding: 5px;
}

.AboutRestaurant_imgdiv{
    padding: 0 20px 0 0;
}
.AboutRestaurant_imgdiv img {
    width: 90px;
    height: 90px;
    object-fit: cover;
    border-radius: 4px;
}
.note{
    margin: 30px 0;
}
.note h4{
    font-weight: bold;
}
.note .note_by_chairman{
    display: flex;
    justify-content: space-around;
}
.note .note_by_chairman div img{
    width: 450px;
    height: 300px;
    margin: 20px 20px 20px 0;
 }
 .note .note_by_chairman div p{
    margin: 20px 0px 20px 20px;
    text-align: justify;
    font-size: 18px;

 }