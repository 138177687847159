.client-box{
    width: 300px;
    border-radius: 6px;
    margin: 10px;
    box-shadow: 0 0 20px rgba(0, 0, 0, .1);
    border: 1px solid #595aff;
    transition: all 0.5s;
    overflow: hidden;
}
.client-box:hover{
    transform: translate(0,5px);
}

.client-box .img-box{
    overflow: hidden;
}
.client-box .imglogo{
    display: flex;
    justify-content: center;
    margin: auto;
     padding: 10px;
}
.client-box .img-box img{
    width: 299px;
    transition: all 0.5s;

}
.client-box .img-box img:hover{
    transform: scale(1.1);

}
.client-box div h3{
    background-color:#595aff;
    color: white;
    padding: 15px;
    text-align: center;
    margin: 0;
}


.client-box div ul {
    list-style: none;
    display: flex;
    padding-left: 0;
    justify-content: center;
    text-align: center;
}

.client-box div ul li p {
    margin-bottom: 2px;
    font-weight: bold;
    font-size: 32px;
}

.client-box div ul li span {
    font-size: 14px;
    font-weight: bold;
}

.client-box div ul li {
    border-top: 1px solid grey;
    padding: 10px 35px;
}

.btn-div-bottom{
    display: flex;
    justify-content: center;
    margin: 40px 0;
}