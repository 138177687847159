.Benefits {
    margin: 30px;
}

.Benefits div h3 {
    margin: 20px 0;
    color: #474846;
}

.bdr {
    border: 2px solid #6AB43E;
    border-radius: 2px;
    padding: 15px;
    height: auto;
}

.bdr h4 {
    margin: 20px 0;
    color: #474846;
}

.bdr .list-icon {
    list-style-type: none;
    padding: 0;
}

.bdr .list-icon li {
    font-size: 14px;
    margin: 20px 0;
    display: flex;
    padding: 0px 0;
    align-items: baseline;
}

.bdr .list-icon li .icons {
    color: #6AB43E;
    margin: 0 10px;
}

.WasteDiv {
    display: flex;
    margin: 10px;
}

.WasteDiv div {
    margin: 5px 10px;

}

.WasteDiv div img {
    width: 250px;
    /* height: 170px; */
    object-fit: cover;
    border-radius: 6px;
}

.WasteDiv div .bold_p {
    font-weight: 500;
    font-size: 19px;
}

.WasteDiv div p,
.w_divp {
    font-size: 18px;
    letter-spacing: 0.5px;
    text-align: justify;
}

@media screen and (max-width:767px) {
    .Benefits {
        margin: 10px;
    }

    .bdr {
        height: auto;
        margin: 20px 0;

    }

    .WasteDiv {
        display: block;
        margin: 10px 0;

    }

    .WasteDiv div {
        margin: 5px 0px;
        text-align: center;
    }
}