.sitemapdivbox {
display: flex;
flex-wrap: wrap;
padding:20px 40px;
}
.sitemapdivbox p{
    /* border-right: 1px solid lightgray; */
    width: auto;
    text-align: center;
    margin:5px;
}
.sitemapdivbox p a{
    text-decoration: none;
    font-weight: bold;
    color: white;
}
.sitemapdivbox p a::after{
    content: "|";
    color: #ffffff;
    margin-left: 6px;
}
.sitemapdivbox p a:hover{
    text-decoration: underline;
}
@media screen and (max-width:767px) {
    .sitemapdivbox {
        display: flex;
        flex-wrap: wrap;
        padding:20px 20px;
        }
}
