.aboutus_div {
    margin: 10px 30px;
}

.aboutus_div p {
    text-align: justify;
    letter-spacing: 1px;
}

.bgclor_VisionMission {
    background-color: rgb(83, 85, 84);
    color: white;
    justify-content: center;
    padding: 20px 0;
    margin-top: 30px;
}
.bgclor_VisionMission div{
    margin: 10px;
}
.bgclor_VisionMission div h2 {
    border-bottom: 3px solid #3aa348;
    padding-bottom: 10px;
    text-align: center;
}

.bgclor_VisionMission div h2 span {
    color: #3aa348;
}
.bgclor_VisionMission div p{
    margin-top: 20px;
    letter-spacing: 1px;
    text-align: justify;
}


.ngo_Awards {
    width: 300px;
    margin: 20px 10px;
    border-radius: 6px;
    text-align: center;
}

.ngo_Awards div img {
    width: 290px;
    height: 180px;
    object-fit: cover;
    border-radius: 3%;
    margin: 20px 0;

}

.ngo_Awards div h5 {
    font-size: 16px;
    font-weight: 500;
    padding-bottom: 10px;
    color: rgb(43, 41, 41);
}

.ngo_Awards div button {
    background-color: white;
    border: 1px solid #3aa348;
    color: #3aa348;
    padding: 8px 20px;
    font-weight: bold;
    letter-spacing: 1px;
    font-size: 15px;
    margin: 10px 0 20px 0;
}

.ngo_Awards div button:hover {
    background-color: white;
    color: #3aa348;
}
.linkdin_div{
    display: flex;
    justify-content: center;
}
.linkdin_div a{
    text-decoration: none;
}

@media only screen and (max-width: 767px) {
    .bgclor_VisionMission {
        padding: 20px;
    }
    .ngo_Awards {
        width: 95%;
    }
    
    .ngo_Awards div img {
        width: 88%;
    
    }
    
}