
.card_body{
    margin: 30px;
    padding: 30px;
    box-shadow:  0 0.25rem 1.125rem rgba(75, 70, 92, 0.1);
}
.contactform label{
    font-size: 14px;
}

.contactform select{
    height: 50px;
}