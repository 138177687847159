
.FeaturedProject_slide {
    padding: 10px 5px;
}

.FeaturedProject_slide a{
    text-decoration: none;
}


.project_fetaureddiv {
    margin: 20px 0px;
    border-radius: 6px;
    border: 5px solid rgb(255, 255, 255);
    box-shadow:  0 0.25rem 1.125rem rgba(75, 70, 92, 0.1);
    padding: 0;
    width: 400px !important;
    background-color: #fff;
}
.project_fetaureddiv .teaminfo{
     display: flex;
     justify-content: space-between;
}

.project_fetaureddiv div img {
    width: 100%;
    height: 380px;
    object-fit: cover;
    border-radius: 1% 1% 0 0;
    margin: 0px 0 20px 0;

}

.project_fetaureddiv div h6 {
    font-weight: bold;
    padding: 0 20px;
    color: black;
    font-size: 16px;
    margin: 0 0 10px 0;

}

.project_fetaureddiv div p {
    padding: 0 20px;
    color: black;
    font-size: 15px;
    font-weight: 500;
    margin-bottom: 5px;

}

@media screen and (min-width: 768px) and (max-width: 992px) {
    .FeaturedProject_slide {
        padding: 10px 5px;
    }

    
}


@media screen and (max-width:767px) {
    .FeaturedProject_slide {
        padding: 10px 10px;
        width: 100%;
    }

    .FeaturedProject_slide h1 {
        font-size: 18px;
    }
    .project_fetaureddiv .teaminfo{
        display:block;
     
   }

    .project_fetaureddiv div img {
        width: 100%;
        height: 300px;
    
    }

    
}

