.Wilmarmain_topdiv {
    margin-bottom: 15%;
}

.Wilmarmain_topdiv img {
    width: 100%;
    height: 100vh;
    object-fit: cover;
    margin-bottom: 0px;
    filter: brightness(40%);
}

.Wilmarmain_topdiv .Wilmarmain_head {
    color: white;
    margin-top: -29%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    filter: brightness(100%);

}

.Wilmarmain_topdiv .Wilmarmain_head h1 {
    font-weight: bold;
    font-size: 70px;
    letter-spacing: 2px;
    text-transform: uppercase;
    text-align: center;
}

.Wilmarmain_topdiv .Wilmarmain_head p {
    font-weight: bold;
    font-size: 20px;
    letter-spacing: 2px;
    margin: 20px 10%;
    text-align: center;
}
.wilmar_div {
    margin: 10px 50px;
}

.wilmar_topdiv {
    text-align: center;
    width: 800px;
    margin: auto;
    padding: 50px 0;
}

.wilmar_topdiv h1 {
    font-size: 40px;
    font-weight: 600;
    margin-bottom: 40px;
}

.wilmar_rowdetail {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    align-items: center;
}

.wilmar_rowdetail .wilmar_col12 {
    width: 300px;
    height: 230px;
    border: 1px solid rgb(243, 238, 238);
    border-radius: 4px;
    margin: 10px;
    display: flex;
    justify-content: center;
    box-shadow:  0 0.25rem 1.125rem rgba(75, 70, 92, 0.1);

}

.our_wilmardiv {
    width: 100%;
}

.our_wilmardiv .iconimgdiv {
    margin: 20px;
    display: flex;
    justify-content: center;
    align-items: center;

}

.our_wilmardiv .iconimgdiv img {
    width: 60px;
    height: 60px;
    object-fit: contain;
    border-radius: 3px;
    margin: 0 ;

}

.our_wilmardiv div h5 {
    font-size: 17px;
    font-weight: 500;
    color: black;
    margin: 20px;
    letter-spacing: 1px;
    text-align: center;
}

.our_wilmardiv div p {
    font-size: 14px;
    margin: 20px;
    text-align: center;

}


.wilmar_culmdiv{
    margin: 0px 0;
    background-color: #f6f6f8;
    color: black;
    padding: 40px 0;
}

.wilmar_culmdiv .wilmare_cocurricular{
    margin: 40px 3%;

}
.wilmar_culmdiv .wilmare_cocurricular h2{
    font-size: 40px;
    text-align: start;
}
.wilmar_culmdiv .wilmare_cocurricular p{
    font-size: 17px;
    letter-spacing: 1px;
    text-align: start;
}
.wilmar_culmdiv .wilmare_cocurricular button{
    background: linear-gradient(to right, #5f6cff, #97fe7b);
    color: white;
    text-transform: capitalize;
    font-size: 20px;
    letter-spacing: 2px;
    padding: 8px 60px;
    margin: 20px 0;
    border-radius: 6px;
    border: none;
}
.bgcolorofthis{
    background-color: #e7f3e4;
}



/* testimonial */
#wilmar_testimonial {
    /* background-color: #e6f1f9; */
    padding-bottom: 10px;
}

.wilmar_h2 {
    font-size: 32px;
    letter-spacing: 1px;
    font-weight: bold;
    padding: 20px 0 40px 30px;
    color: #56594d;
    text-align: center;
}

.wilmar_testimonial_container {
    width: 100%;
    padding-bottom: 1rem;
}

.testimonial_div_wilmar {
    display: flex;
    /* justify-content: space-between; */
}

.wilmar_testimonial_container .swiper-wrapper {
    width: 100%;
}

.wilmar_testimonial_container .swiper-wrapper .swiper-slide {
    width: 100% !important;
    /* margin: 0 5px; */
}

.img_div_avatar {
    width: 50rem;
    height: 400px;
    aspect-ratio: 1/1;
    overflow: hidden;
    border-radius: 2%;
    margin: 0 40px 0 10px !important;
    border: 0.4rem solid #8acd93;
}

.wilmar_testimonial_slide {
    background: #d9e8f8;
    padding: 2rem;
    border-radius: 2rem;
    user-select: none;
    height: 500px;
    margin: 30px 0;
}

.wilmar_name {
    font-size: 18px;
    font-weight: 500;
    margin-top: 50px;
    font-style: italic;
}

.wilmar_review {
    color: #000000;
    display: flex;
    font-size: 16px;
    font-style: italic;

}

.wilmar_message {
    color: #000000;
    display: flex;
    font-size: 16px;
    font-style: italic;
    align-items: center;
}


/* end testimonial */



@media screen and (max-width:767px) {
    .Wilmarmain_topdiv {
        margin-bottom: 22%;
    }
    
    .Wilmarmain_topdiv img {
        height: 400px;
    }

    .Wilmarmain_topdiv .Wilmarmain_head {
        margin-top: -62%;

    }

    .Wilmarmain_topdiv .Wilmarmain_head h1 {
        font-size: 26px;
    }

    .Wilmarmain_topdiv .Wilmarmain_head p {
        font-size: 10px;
        margin-top: 10px;
        padding: 0 10px;
    }
    .wilmar_div {
        margin: 10px 20px;
    }

    .wilmar_topdiv {
        width: 100%;
        padding: 20px;
    }

    .wilmar_topdiv h1 {
        font-size: 26px;
    }

    .wilmar_topdiv p {
        font-size: 14px;
    }

    .our_wilmardiv {
        text-align: center;
    }

    .our_wilmardiv .iconimgdiv {
        margin: 10px;
    }

    .our_wilmardiv .iconimgdiv img {
        margin: 0;

    }

    .our_wilmardiv div h5 {
        margin: 20px 20px 0 20px;
    }

    .our_wilmardiv div p {
        font-size: 16px;
        margin: 20px;
    }

    .wilmar_culmdiv .wilmare_cocurricular h2{
        font-size: 30px;
    }

    

    .testimonial_div_wilmar {
        display: block;
    }
    .wilmar_testimonial_slide {
        background: #d9f8dd;
        padding: 2rem 1rem;
        border-radius: 1rem;
        user-select: none;
        height: auto !important;
        margin: 30px 0;
    }

    .img_div_avatar {
        width: 99%;
        height: 210px;
        aspect-ratio: 1/1;
        overflow: hidden;
        border-radius: 2%;
        margin:1px;
        border: 0.4rem solid #8acd93;
    }

   
}