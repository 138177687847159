.maqInfrastructure_topdiv {
    margin-bottom: 18%;
}

.maqInfrastructure_topdiv img {
    width: 100%;
    height: 90vh;
    object-fit: cover;
    margin-bottom: 0px;
    filter: brightness(60%);
}

.maqInfrastructure_topdiv .maqInfrastructure_head {
    color: white;
    margin-top: -25%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    filter: brightness(100%);

}

.maqInfrastructure_topdiv .maqInfrastructure_head h1 {
    font-weight: bold;
    text-transform: uppercase;
    font-size: 70px;
    letter-spacing: 2px;
    text-shadow: 2px 2px 15px rgb(15, 15, 15), 2px 2px 15px rgb(19, 20, 20);
}
.PrintSharediv{
    background-color: #0b5d66;
    color: white;
    padding: 20px 10px;
    display: flex;
    justify-content: flex-end;
}

.PrintSharediv ul{
    list-style: none;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    width: 300px;
    margin: 0;
}
.PrintSharediv ul li a{
    color: white;
}
.PrintSharediv ul li a .iconsocial{
    font-size: 22px;
}
.futurebusinessdiv{
    padding: 50px;
}

.futurebusinessdiv h1{
    font-size: 45px;
    font-weight: bold;
}
.futurebusinessdiv p{
    font-size: 20px;
}

.InfrastructureInvestment {
    display: flex;
    justify-content: space-evenly;
    flex-wrap: wrap;
    align-items: center;
}

.InfrastructureInvestment .Infrastructure_col12 {
    width: 380px;
    height: 300px;
    border: 1px solid rgb(243, 238, 238);
    border-radius: 6px;
    margin: 10px;
    display: flex;
    justify-content: center;
    box-shadow:  0 0.25rem 1.125rem rgba(75, 70, 92, 0.1);
    background-color: #fff;
}

.ourimgdiv_Infrastructure {
    width: 100%;
}

.ourimgdiv_Infrastructure .imgdiv {
    margin: 20px;
    display: flex;
    justify-content: center;
    align-items: center;

}

.ourimgdiv_Infrastructure .imgdiv img {
    width: 100px;
    height: auto;
    object-fit: contain;
    border-radius: 3px;
    margin: 10px 0 ;

}

.ourimgdiv_Infrastructure div h5 {
    font-size: 28px;
    font-weight: bolder;
    color: black;
    margin: 20px;
    letter-spacing: 1px;
    text-align: center;
}

.ourimgdiv_Infrastructure div p {
    font-size: 18px;
    margin: 20px;
    text-align: center;

}

.infrastructureprogram{
    display: flex;
    align-items: center;
    padding: 50px;
    background-color: #f7f3f3;

}

.infrastructureprogram div img{
    width: 900px;
    height: 90vh;
    z-index: -1;
}

.infrastructureprogram .programtextdiv{
    border: 1px solid lightgray;
    background-color: #fff;
    padding: 50px;
    width: 500px;
    height: auto;
    font-size: 20px;
    font-weight: 500;
    display: flex;
    align-items: center;
    margin-left: -10%;
    z-index: 1;

}

.infrastructureprogram .programtextdiv p{
    margin: 0;
    line-height: 40px;
}


.infrastructureprogram_left{
    display: flex;
    align-items: center;
    padding: 50px;
    background-color: #f7f3f3;

}

.infrastructureprogram_left div img{
    width: 900px;
    height: 130vh;
    z-index: -1;

}

.infrastructureprogram_left .programtextdiv_left{
    border: 1px solid lightgray;
    background-color: #fff;
    padding: 50px;
    width: 500px;
    height: auto;
    font-size: 20px;
    font-weight: 500;
    display: flex;
    align-items: center;
    margin-right: -10%;
    z-index: 1;

}

.infrastructureprogram_left .programtextdiv_left p{
    margin: 0;
    line-height: 40px;
}

.Explore_morediv {
    padding: 50px 10px;
    background-color: #0b5d66;
}

.Explore_dddd{
    box-shadow: 0 0.25rem 1.125rem rgba(75, 70, 92, 0.1);
    margin: 0 10px;
    width: 450px;
    padding: 20px 0;
    color: white;
}
.Explore_dddd:not(:last-child) {
    border-right: 1px solid rgb(145, 142, 142);
}


.Explore_dddd .readbtnbox {
    font-size: 13px;
    border: 1px solid rgb(243, 238, 238);
    font-weight: 600;
    padding: 12px 30px;
    letter-spacing: 2px;
    margin: 10px 0 0 50px;
    border-radius: 10px;
    color: white;
}

.Explore_dddd .readbtnbox:hover {
    background-color: white;
    color: black;
}


.Explore_morediv_customize {
    width: 100%;
    border-radius: 6px;
    color: white;
    text-align: center;

}


.Explore_morediv_customize div h5 {
    font-size: 32px;
    font-weight: bold;
    margin: 20px 30px 0 30px;
    letter-spacing: 1px;
}

.Explore_morediv_customize div p {
    font-size: 18px;
    padding-bottom: 10px;
    text-align: justify;
    margin: 20px 30px;
}

.Opportunities_togrow {
    margin: 0px 0;
    background-color: #f5f5f5;
    color: rgb(42, 41, 41);
    padding: 40px 0;

}

.Opportunities_togrow_alldetail {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin-right: 0;
}

.Opportunities_togrow_alldetail a {
    color: white;
}

.linktag_Opportunities_togrow {
    text-decoration: none;
    padding: 0;
    background-color: #fff;
    border-radius: 6px;
    width: 300px;
    box-shadow:  0 0.25rem 1.125rem rgba(75, 70, 92, 0.1);
    /* height: 500px; */
}


.Opportunities_togrow_areadiv {
    width: 100%;
    margin: 20px 0px;
    border-radius: 1%;
    color: black;
}

.Opportunities_togrow_areadiv div img {
    width: 100%;
    height: 200px;
    object-fit: cover;
    margin: -20px 0 20px 0;

}

.Opportunities_togrow_areadiv div h6 {
    font-weight: bold;
    padding: 0 20px;
    font-size: 24px;
    margin: 0 0 10px 0;
    height: 75px;

}

.Opportunities_togrow_areadiv div p {
    padding: 0 20px;
    font-size: 17px;
    height: 100px;

}
.Opportunities_togrow_areadiv div .linep{
    font-size: 30px;
    margin: 0;
}

.Opportunities_togrow_areadiv div .opportunity_btn {
    background-color: #e7e2e2;
    border-top: 1px solid #d0d0ce;
    color: #62666a;
    font-weight: bold;
    font-size: 18px;
    padding: 20px 0;
    text-align: center;
    margin-bottom: -20px;
    border-radius: 0 0 6px 6px;
}


.ContactUsToday{
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 50px;
    background-color: #f7f3f3;

}

.ContactUsToday div img{
    width: 500px;
    height: 400px;
    z-index: -1;
}

.ContactUsToday .contacttextdiv{
    border: 1px solid lightgray;
    background-color: #fff;
    padding: 50px;
    width: 700px;
    height: auto;
    font-size: 20px;
    font-weight: 500;
    margin-left: -10%;
    z-index: 1;

}

.ContactUsToday .contacttextdiv p{
    margin: 0;
    line-height: 40px;
}

.ContactUsToday .input-group {
    border: 1px solid lightgray;
    border-radius: 4px;
    width: 600px;
    margin-top: 40px;
}
.ContactUsToday .input-group input {
    width: 503px;
    border: none;
    margin-left: 0px;
    padding-left: 15px;

}.ContactUsToday .input-group input::placeholder {
    font-size: 16px;
    font-weight: 500;
}
.ContactUsToday .input-group input:focus {
    outline: none;
    border: none;
}
.ContactUsToday .input-group button {
    background: #0b5d66;
    color: white;
    text-transform: capitalize;
    padding: 10px 20px;
    font-size: 17px;
}

.connect_social{
    padding: 80px 0;
}
.connect_social h1{
    text-align: center;
    font-size: 24px;
    font-weight: bold;
    padding-bottom: 80px;

}
.iconimge_social{
    display: flex;
    justify-content: center;
    align-items: center;
}
.iconimge_social a{
    display: flex;
    justify-content: center;
    align-items: center;
    text-decoration: none;

}
.iconimge_social img{
    width: 30px;
    margin-right: 10px;
}
.iconimge_social h6{
    margin: 0;
    font-weight: bold;
}
.iconimge_social .YouTube{
    color: rgb(191, 14, 14);
}
.iconimge_social .Facebook{
    color: #3c5997;
}

.iconimge_social .Linkedin{
    color: #027bb6;
}
.iconimge_social .Twitter{
    color: #1c1d1d;
}


@media screen and (max-width:767px) {

    .maqInfrastructure_topdiv {
        margin-bottom: 24%;
    }

    .maqInfrastructure_topdiv img {
        height: 60vh;
    }

    .maqInfrastructure_topdiv .maqInfrastructure_head {
        margin-top: -45%;

    }

    .maqInfrastructure_topdiv .maqInfrastructure_head h1 {
        font-size: 30px;
        text-align: center;
    }
    .Explore_morediv {
        margin: 10px 20px;
    }

    .Explore_morediv_customize {
        text-align: center;
    }

    .Explore_morediv_customize div h5 {
        margin: 20px 20px 0 20px;
    }

    .Explore_morediv_customize div p {
        font-size: 16px;
        margin: 20px;
    }
    .linktag_Opportunities_togrow {
        width: 85%;
    }
}