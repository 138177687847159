.Ecosystem_Benefits {
    margin: 30px;
}

.Ecosystem_Benefits div h3 {
    margin: 20px 0;
    color: #474846;
}

.Ecosystem_bdr {
    border: 2px solid #6AB43E;
    border-radius: 2px;
    padding: 15px;
    height: auto;
}

.Ecosystem_bdr h4 {
    margin: 20px 0;
    color: #474846;
}

.Ecosystem_bdr .list-icon {
    list-style-type: none;
    padding: 0;
}

.Ecosystem_bdr .list-icon li {
    font-size: 14px;
    margin: 20px 0;
    display: flex;
    padding: 0px 0;
    align-items: baseline;
}

.Ecosystem_bdr .list-icon li .icons {
    color: #6AB43E;
    margin: 0 10px;
}
.flowChart3{
    margin: 40px;
}
.flowChart3 img{
    width: 100%;
    object-fit: cover;
}


@media screen and (max-width:767px) {
    .Ecosystem_Benefits {
        margin: 10px;
    }

    .Ecosystem_bdr {
        height: auto;
        margin: 20px 0;

    }
    .flowChart3{
        margin: 40px 10px;
    }
}