.MAQHeader {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: rgb(0, 0, 0);
  backdrop-filter: blur(15px);
  /* box-shadow: 0 0 2px white; */
  padding: 1rem 0.4rem;
  position: sticky !important;
  top: 0 !important;
  z-index: 9999;
  color: white;

}

.MAQHeader_mobile {
  display: none;
}

.MAQHeader_mobile button .fs-1textwhite {
  color: white;
  font-size: 38px;
}

.MAQHeader .logo {
  width: 3.5rem;
  margin-left: 34px;
  /* border-radius: 0.8rem; */
  border-radius: 0.2rem;
}

.MAQHeader a {
  text-decoration: none;
  display: flex;
  align-items: center;

}

.MAQHeader .compnyName {
  font-weight: bold;
  font-size: 24px;
  margin-left: 10px;
  color: white;
}

.MAQHeader .MAQHeader_liauto {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  list-style: none;
  margin: 0;
}

.MAQHeader .MAQHeader_liauto li {
  margin: 0 .6rem;

}

.MAQHeader .MAQHeader_liauto li a {
  padding: 0.5rem 0.6rem;
  border-radius: 6px;
  text-decoration: none;
  color: white;
}


.MAQHeader .MAQHeader_liauto li a:hover {
  background: rgba(142, 186, 241, 0.3);
  border-radius: 6px;
}

.MAQHeader .MAQHeader_liauto li a.active {
  background: #572f29;
  color: white;
  padding: 0.5rem 1rem;
  border-radius: 6px;

}

.nav_list {
  position: relative;

}

.hideSolution {
  display: none;
  position: absolute;
  z-index: 10;
  color: black;
  margin-top: 1px;
  left: -806%;
}

.emptydiv{
   background-color: unset;
   padding-top: 20px;
}

.nav_list:hover .hideSolution {
  display: block;
}

.nav_list .ul_div {
  list-style: none;
  display: flex;
  /* justify-content:  space-between; */
  flex-wrap: wrap;
  background-color: white;
  color: #1c1b1b;
  /* border: 3px solid gray; */
  border-radius: 4px;
  box-shadow: 0 0.25rem 1.125rem rgba(75, 70, 92, 0.1);
  width: 1140px;
  padding: 20px 50px 50px 50px;
  height: 70vh;
  overflow-y: scroll;

}

.nav_list .ul_div li p {
  margin: 0;
  display: flex;
  align-items: center;
}

.nav_list .ul_div li {
  border-bottom: 1px solid lightgray;
  padding: 10px;
  width: 490px;
  font-size: 16px;
  font-weight: 500;
  cursor: pointer;
  border-radius: 5px;
  text-transform: uppercase;

}

.nav_list .ul_div li:hover {
  background-color: #572f29;
  color: white;
  border-radius: 5px;

}

.hidegroup {
  display: none;
  position: absolute;
  background-color: white;
  z-index: 10;
  color: black;
  border-radius: 4px;
  margin-top: 1px;
  box-shadow: 0 0.25rem 1.125rem rgba(75, 70, 92, 0.1);
  left: 1%;
  width: 240px;
}

.nav_list:hover .hidegroup {
  display: block;
}

.nav_list .OurGroup_ul {
  list-style: none;
  display: flex;
  flex-wrap: wrap;
  background-color: white;
  color: #1c1b1b;
  border-radius: 4px;
  box-shadow: 0 0.25rem 1.125rem rgba(75, 70, 92, 0.1);
  width: 290px;
  padding: 20px 0;

}
.nav_list .OurGroup_ul li {
  border-bottom: 1px solid lightgray;
  padding: 10px;
  width: 400px;
  font-size: 16px;
  font-weight: 500;
  cursor: pointer;
  border-radius: 5px;

}

.nav_list .OurGroup_ul li:hover {
  background-color: #572f29;
  color: white;
  border-radius: 5px;

}

.nav_list .OurGroup_ul li a {
  padding:0;
  border-radius: 6px;
  text-decoration: none;
  color: black;
}


.nav_list .OurGroup_ul li a:hover {
  background: unset;
  border-radius: 0px;
  color: white;

}

.nav_list .OurGroup_ul li a.active {
  background: #572f29;
  color: white;
  padding: 0.5rem 1rem;
  border-radius: 6px;

}

@keyframes moveDown {
  from {
    transform: translateY(-5rem);
  }

  to {
    transform: translateY(0rem);
  }
}

@media only screen and (max-width: 767px) {
  .MAQHeader {
    z-index: 2;
    width: 100%;

  }

  .MAQHeader_rightUL {
    display: none;
  }

  .MAQHeader_mobile {
    display: block;
    width: 230px;
  }

  .MAQHeader .logo {
    width: 3.2rem;
    margin: 0 10px;
    border-radius: 0.5rem;
  }

  .MAQHeader .compnyName {
    display: none;
  }
}