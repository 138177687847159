.InfraMart_maintopdiv {
    margin-bottom: 15%;
}

.InfraMart_maintopdiv img {
    width: 100%;
    height: 100vh;
    object-fit: cover;
    margin-bottom: 0px;
    filter: brightness(40%);
}

.InfraMart_maintopdiv .InfraMartmain_head {
    color: white;
    margin-top: -35%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    filter: brightness(100%);

}

.InfraMart_maintopdiv .InfraMartmain_head h1 {
    font-weight: bold;
    font-size: 70px;
    letter-spacing: 2px;
    text-transform: uppercase;
    text-align: center;
}

.InfraMart_maintopdiv .InfraMartmain_head p {
    font-weight: bold;
    font-size: 20px;
    letter-spacing: 2px;
    margin: 20px 10%;
    text-align: center;
}

.InfraMart_div {
    margin: 10px 50px;
}

.InfraMart_topdiv {
    text-align: center;
    width: 800px;
    margin: auto;
    padding: 50px 0;
}

.InfraMart_topdiv h1 {
    font-size: 40px;
    font-weight: 600;
    margin-bottom: 40px;
}

.InfraMart_topdiv p {
    font-size: 16px;
}

.OurStrength h4 {
    text-align: center;
    font-weight: bold;
    letter-spacing: 0.3px;
    margin: 20px 0;
}

.rowdetail {
    display: flex;
    justify-content: space-evenly;
    flex-wrap: wrap;
    align-items: center;
}

.rowdetail .infra_col12 {
    width: 300px;
    height: 230px;
    border: 1px solid rgb(243, 238, 238);
    border-radius: 4px;
    margin: 20px;
    display: flex;
    justify-content: center;
    box-shadow:  0 0.25rem 1.125rem rgba(75, 70, 92, 0.1);

}

.our_infratechdiv {
    width: 100%;
}

.our_infratechdiv .iconimgdiv {
    margin: 20px;
    display: flex;
    justify-content: center;
    align-items: center;

}

.our_infratechdiv .iconimgdiv img {
    width: 100px;
    height: 80px;
    object-fit: contain;
    border-radius: 3px;
    margin: 0 ;

}

.our_infratechdiv div h5 {
    font-size: 20px;
    font-weight: 500;
    color: black;
    margin: 20px;
    letter-spacing: 1px;
    text-align: center;
}

.our_infratechdiv div p {
    font-size: 16px;
    margin: 20px;
    text-align: center;

}

.ecosystem_rowdetail {
    display: flex;
    justify-content: space-evenly;
    flex-wrap: wrap;
    align-items: center;
    margin: 30px;
}

.ecosystem_rowdetail .ecosystemdiv {
    width: 300px;
    height: 100px;
    border-radius: 4px;
    margin: 20px;
    display: flex;
    justify-content: center;

}
.ecosystem_rowdetail .ecosystemdiv div{
    text-align: center;
}
.ecosystem_rowdetail .ecosystemdiv div h5{
    font-size: 40px;
    font-weight: bold;
}

.ecosystem_rowdetail .ecosystemdiv div p{
    font-size: 20px;
    font-weight: 500;
}

.bgggggg{
    border: 1px solid rgb(243, 238, 238);
    box-shadow:  0 0.25rem 1.125rem rgba(75, 70, 92, 0.1);
    border-radius: 4px;
    margin: 0 10px;
    width: 310px;
}

.missionvision{
    margin: 0px 40px;
}
.missionvision div img{
     width: 100%;
}
.Valuesdiv{
     display: flex;
     flex-direction: column;
     justify-content: center;
     margin: 0 50px;
}
.Valuesdiv img{
    width: 100%;
    object-fit: contain;
}

.Projects_topimg{
    border: 1px solid lightgray;
    box-shadow:  0 0.25rem 1.125rem rgba(75, 70, 92, 0.1);
    border-radius: 4px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 20px;
    width: 90% !important;
    padding: 20px;
}

.Projects_topimg img {
    height: 100px;
    width: 100%;
    object-fit: cover;
    margin: auto;
    align-items: center;
    border-radius: 4px;
}

.Projects_topimg p {
    font-weight: bold;
    margin-top: 20px;
}

.Partners_topimg{
    border-radius: 4px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 20px;
    width: 90% !important;
    padding: 20px;
}
.StrategicInvestments{
    border: 1px solid rgb(243, 238, 238);
    box-shadow:  0 0.25rem 1.125rem rgba(75, 70, 92, 0.1);
    border-radius: 4px;
    margin: 0 10px;
    width: 400px;   
}

.btun{
 margin: 0 40px 20px 40px;
}


.infraTeam_weworkIn {
    margin: 0px 0;
    background-color: rgb(232, 238, 243);
    color: black;
    padding: 40px 0;
}

.infraTeam_alldetail {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin-right: 0;
}

.infraTeam_alldetail a {
    color: black;
}

.linktag_infraTeam {
    text-decoration: none;
    border: 1px solid rgb(159, 154, 154);
    box-shadow:  0 0.25rem 1.125rem rgba(75, 70, 92, 0.1);
    border-radius: 4px;
    padding: 0;
    width: 310px;

}


.infraTeam_areadiv {
    width: 100%;
    margin: 20px 0px;
    border-radius: 6px;
}
.infraTeam_areadiv .teaminfo{
     display: flex;
     justify-content: space-between;
}

.infraTeam_areadiv div img {
    width: 100%;
    height: 280px;
    object-fit: cover;
    border-radius: 1% 1% 0 0;
    margin: -20px 0 20px 0;

}

.infraTeam_areadiv div h6 {
    font-weight: 600;
    padding: 0 20px;
    color: black;
    font-size: 17px;
    margin: 0 0 10px 0;

}

.infraTeam_areadiv div p {
    padding: 0 20px;
    color: black;
    font-size: 14px;

}

.accordioncontainer{
    width: 90%;
    margin: auto;
    padding: 30px 0;
}


.accordioncontainer .MuiPaper-root {
    background-color: rgb(235, 233, 233);
    color: #1c1b1b;
    padding-left: 0;
    padding-right: 0;
    box-shadow: unset;
    border-radius: 4px;
    margin: 20px 0;
}

.accordioncontainer .accordion-bigdiv {
    border-radius: 8px;
    margin: 10px 0;
    padding: 5px;

}

.accordioncontainer .bigdiv-ques {
    font-weight: 500 !important;
    font-size: 20px !important;
}

.accordioncontainer .bigdiv-answr {
    background-color: white;
    font-size: 16px !important;
    padding: 10px 20px;
    letter-spacing: 1px !important;

}

@media screen and (max-width:767px) {
    .InfraMart_maintopdiv img {
        height: 400px;
    }

    .InfraMart_maintopdiv .InfraMartmain_head {
        margin-top: -62%;

    }

    .InfraMart_maintopdiv .InfraMartmain_head h1 {
        font-size: 26px;
    }

    .InfraMart_maintopdiv .InfraMartmain_head p {
        font-size: 10px;
        margin-top: 10px;
        padding: 0 10px;
    }

    .sliderinfratop{
        margin-top: 80px;
    }
    .InfraMart_div {
        margin: 10px 20px;
    }

    .InfraMart_topdiv {
        width: 100%;
        padding: 20px;
    }

    .InfraMart_topdiv h1 {
        font-size: 26px;
    }

    .InfraMart_topdiv p {
        font-size: 14px;
    }

    .our_infratechdiv {
        text-align: center;
    }

    .our_infratechdiv .iconimgdiv {
        margin: 10px;
    }

    .our_infratechdiv .iconimgdiv img {
        margin: 0;

    }

    .our_infratechdiv div h5 {
        margin: 20px 20px 0 20px;
    }

    .our_infratechdiv div p {
        font-size: 16px;
        margin: 20px;
    }

    .logistic_clients h1 {
        font-size: 26px;
    }
    .accordioncontainer .bigdiv-ques {
        font-size: 17px !important;
    }
    .accordioncontainer .bigdiv-answr {
        font-size: 14px !important;
    
    }
}