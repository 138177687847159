.footerdiv {
    background: #ba0505;
    padding-top: 30px;
    color: white;
}

.footerdiv .topfooterblock {
    padding-bottom: 30px;
}

.footerdiv .topfooterblock .mainwapdiv {
    display: block !important;
    justify-content: unset !important;
    max-width: 100%;
    width: 100%;
    margin: 0 auto;
    padding-left: 1px;
    padding-right: 1px;
}

.footerdiv .topfooterblock .mainwapdiv .aboutblock {
    flex: 0 0 200px;
    max-width: 100%;
    margin-right: 1px;
    margin-left: 1px;
}

.footerdiv .topfooterblock .mainwapdiv .aboutblock h2 {
    font-weight: bold;
    text-align: center;
    color: white;
    font-size: 26px;
    display: block;
    margin-bottom: 20px;
}

.footerdiv .topfooterblock .mainwapdiv .aboutblock ul {
    list-style: none;
    display: flex;
    flex-wrap: wrap !important;
    justify-content: center;
    margin: 20px 10px;
    padding-left: 1px;
}

.footerdiv .topfooterblock .mainwapdiv .aboutblock ul li {
    margin: 10px 20px;
}

.footerdiv .topfooterblock .mainwapdiv .aboutblock ul li a {
    margin-bottom: 4px;
    list-style: none;
    color: white;
    text-decoration: none;
    font-size: 18px;
    padding-left: 2px;
}

.visiblesubscribe {
    display: block;
    position: absolute;
    background-color: white;
    padding: 10px;
    border-radius: 6px;
    width: 350px;
    margin-left: -12%;
}

.hiddensubscribe {
    display: none;
}

.emailtoggle {
    display: flex;
    align-items: center;
    border: 2px solid rgb(58, 107, 199);
    border-radius: 6px;
}

.css-1d3z3hw-MuiOutlinedInput-notchedOutline {
    display: none;
}

.emailtoggle input {
    padding: 0 4px;
    height: 40px;
    width: 238px;
    border: none;
    /* Remove border */
}

.emailtoggle input:focus {
    outline: none !important;
}

.emailtoggle button {
    border-radius: 0 4px 4px 0;
    margin-left: -2px;
    height: 40px;
}

.topfooterblock .secondblock {
    flex: 0 0 296px;
    max-width: 100%;
    margin: 10px 5px;
    display: block;
    text-align: center;
}

.topfooterblock .secondblock h3 {
    margin: 30px 0 20px 0;
    font-weight: bold;
    font-size: 22px;
}

.topfooterblock .secondblock .block {
    margin: 10px 20px;
    display: flex !important;
    align-items: center !important;
    justify-content: center;
}

.topfooterblock .secondblock .block h2 {
    margin: 0 !important;
    /* margin-bottom: 0px;
    margin-right: 10px; */
    font-size: 20px;
}

.topfooterblock .secondblock .block .main_ptagg {
    color: white;
    text-decoration: none;
    display: flex;
    justify-content: space-around;
    font-size: 16px;
    margin-right: 10px;
}

.topfooterblock .secondblock div p {
    margin: 0 10px;
    cursor: pointer;
}

.topfooterblock .fourth-block {
    flex: 0 0 310px;
    max-width: 100%;

}

.topfooterblock .fourth-block p {
    font-size: 14px;
    color: white;
    line-height: 21px;
    margin-bottom: 45px;
    font-weight: 400;
    text-align: justify;
}

.innersocial {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

.innersocial a {
    margin-right: 19px;
}

.footer-company {
    margin: 35px 0 0 0;
}

.footer-company a {
    color: white;
    text-decoration: none;
    outline: none;
    transition: all 0.5s;
}

.footerdiv .midfooterblock {
    border-top: 1px solid #ffffff;
    padding: 20px 0;

}

.footerdiv .midfooterblock .block h2 {
    font-weight: bold;
    font-size: 22px;
    margin: 10px 0 20px 0;
}

.footerdiv .midfooterblock .mainwapper {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    max-width: 1140px;
    width: 100%;
    margin: 0 auto;
    padding-left: 15px;
    padding-right: 15px;

}

.footerdiv .midfooterblock .mainwapper a.fill {
    background: #595aff;
    border: 1px solid#ffffff;
    font-size: 17px;
    font-weight: 700;
    color: white;
    text-align: center;
    border-radius: 30px;
    padding: 10px 30px;
    transition: all 0.5s;
    text-decoration: none;
    margin-right: 20px;

}

.footerdiv .midfooterblock .mainwapdiv a {
    margin-right: 15px;
    flex: 0 0 178px;
    max-width: 100%;
    text-decoration: none;
}

.footerdiv .midfooterblock .mainwapper a.outline {
    background: transparent;
    border: 1px solid #ffffff;
    font-size: 17px;
    font-weight: 700;
    color: white;
    text-align: center;
    border-radius: 30px;
    padding: 10px 35px;
    transition: all 0.5s;
    text-decoration: none;


}

.fotrblack {
    display: flex;
    justify-content: space-evenly;
    background-color: black;
}

.footerdiv .copyrightblock {
    background: black;
    padding: 15px 0;

}

.maincopyrightwapper {
    max-width: 1140px;
    width: 100%;
    margin: 0 auto;
    padding-left: 2px;
    padding-right: 2px;
    justify-content: center;
    display: flex;
}

.footerdiv .copyrightblock .copy {
    flex: 1;
    font-size: 14px;
    color: #ffffff;
    display: flex;
    justify-content: center;
}

.privacypolicy a {
    color: white;
    margin: 0 10px;
    text-decoration: none;
    font-size: 14px;
}

.designdevelop p {
    font-size: 14px;
}

.designdevelop p a {
    text-decoration: none;
    color: white;

}

.copyrightblock {
    display: block;
    justify-content: center;
}

.footrlogo {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 25%;
}

@media screen and (max-width:767px) {
    .footerdiv {
        display: block !important;
    }

    .footerdiv .topfooterblock .mainwapdiv {
        display: block !important;
        padding: 5px;
    }

    .footerdiv ul {
        display: flex !important;
        flex-wrap: wrap !important;
        margin: 20px 2px !important;
        padding: 1px;
    }

    .footerdiv .topfooterblock .mainwapdiv .aboutblock {
        flex: 0 !important;
        margin: 0 !important;
    }


    .topfooterblock .aboutblock ul {
        display: flex !important;
        justify-content: space-around !important;
        margin: 1px !important;
    }

    .footerdiv .topfooterblock .mainwapdiv .aboutblock ul li {
        margin: 5px;
    }

    .topfooterblock .aboutblock ul li {
        width: 160px;
    }

    .topfooterblock .aboutblock ul li a {
        padding: 2px;
    }

    .topfooterblock .secondblock {
        margin: 30px 10px;
        display: block !important;
    }

    .topfooterblock .secondblock .block {
        margin: 10px 10px;
        display: block !important;
        line-height: 40px;
    }

    .topfooterblock .secondblock .block div p {
        display: flex;
        justify-content: center;
    }

    .topfooterblock .secondblock .block .main_ptagg {
        display: block;
        justify-content: center;
    }

    .topfooterblock .fourth-block {
        margin: 30px;
    }

    .maincopyrightwapper {
        display: block;
        justify-content: center;
        padding: 10px;
    }

    .footerdiv .midfooterblock .mainwapper a.outline {
        margin-top: 10px;
    }

    .fotrblack {
        display: block !important;
        padding: 10px 20px;
        text-align: center;
    }

    .footrlogo {
        display: none;
    }

    .visiblesubscribe {
        width: 80%;
        margin-left: -19%;
        margin-top: 10px;
    }

    .emailtoggle {
        display: flex;
        align-items: center;
        border: 2px solid rgb(58, 107, 199);
        border-radius: 6px;
        height: auto;
    }

    .emailtoggle input {
        padding: 0 5px;
        margin-top: -15px;
        border: none;
        max-height: 70px;
        width: 98%;
    }

    .emailtoggle input:focus {
        outline: none !important;
    }

    .emailtoggle button {
        border-radius: 0 4px 4px 0;
        margin-left: -2px;
        height: 42px;
    }



}

@media screen and (min-width: 768px) and (max-width: 992px) {
    .topfooterblock .secondblock {
        margin: 30px 5px;
        display: block !important;
    }

    .topfooterblock .secondblock .block {
        margin: 10px 5px;
        display: flex !important;
        align-items: center !important;
        justify-content: center;
    }

    .topfooterblock .secondblock .block a {
        text-align: start;
    }

    .visiblesubscribe {
        width: 350px;
        margin-left: -30%;
        margin-top: 10px;
    }

    .emailtoggle {
        display: flex;
        justify-content: space-between;
        align-items: center;
        border: 2px solid rgb(58, 107, 199);
        border-radius: 6px;
        height: 45px;
        width: 100%;
    }

    .emailtoggle input {
        padding: 0 5px;
        margin-top: 0px;
        border: none;
        width: 250px !important;
        height: 38px;
    }

    .emailtoggle input:focus {
        outline: none !important;
    }

    .emailtoggle button {
        border-radius: 0 4px 4px 0;
        margin-left: -2px;
        height: 40px;
    }

}