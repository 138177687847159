.activitiesDiv {
    margin: 0 20px;
}

.activitiesDiv img {
    width: 100%;
}

.activitiesDiv h4 {
    font-weight: bold;
    text-align: center;
    letter-spacing: 0.6px;
    margin: 20px 0;
}

.activitiesDiv p {
    letter-spacing: 0.6px;
    margin: 20px;
}

.more_activities {
    display: flex;
    margin: 40px;
}

.more_activities div {
    margin: 5px 30px;

}

.more_activities div img {
    width: 150px;
    height: 150px;
    object-fit: cover;
    border-radius: 6px;
}

.more_activities div .bold_p {
    font-weight: 500;
    font-size: 19px;
}

.more_activities div p {
    font-size: 18px;
    letter-spacing: 0.5px;
}



/* testimonial */
#ngo_testimonial {
    /* background-color: #e6f1f9; */
    padding-bottom: 10px;
}

.testi_h2 {
    font-size: 32px;
    font-weight: bold;
    padding: 20px 0 40px 30px;
    color: #56594d;
    text-align: center;
}

.ngo_testimonials__container {
    width: 100%;
    padding-bottom: 1rem;
}

.ngo_testimonial_div {
    display: flex;
    /* justify-content: space-between; */
}

.ngo_testimonials__container .swiper-wrapper {
    width: 100%;
}

.ngo_testimonials__container .swiper-wrapper .swiper-slide {
    width: 100% !important;
    /* margin: 0 5px; */
}

.img_div_client__avatar {
    width: 25rem;
    height: 230px;
    aspect-ratio: 1/1;
    overflow: hidden;
    border-radius: 2%;
    margin: 0 40px 0 10px !important;
    border: 0.4rem solid #8acd93;
}

.ngo_testimonial {
    background: #d9f8dd;
    padding: 2rem;
    border-radius: 2rem;
    user-select: none;
    height: 300px !important;
    margin: 30px 0;
}

.ngo_client__name {
    font-size: 18px;
    font-weight: 500;
    margin-top: 50px;
    font-style: italic;
}

.ngo_client__review {
    color: #000000;
    display: flex;
    font-size: 16px;
    font-style: italic;

}

.ngo_client__message {
    color: #000000;
    display: flex;
    font-size: 16px;
    font-style: italic;
    align-items: center;
}

.certificatediv {
    margin: 20px;
}

.certificatediv div img {
    width: 400px;
    height: 300px;
    border-radius: 4px;
    object-fit: cover;
}



/* end testimonial */


@media screen and (max-width:767px) {
    .more_activities {
        display: block;
        margin: 40px 10px;
    }

    .more_activities div {
        margin: 10px;

    }

    .more_activities div img {
        width: 98%;
        height: 200px;
        object-fit: cover;
        border-radius: 6px;
    }

    .more_activities div p {
        text-align: justify;
    }




    .ngo_testimonial_div {
        display: block;
    }
    .ngo_testimonial {
        background: #d9f8dd;
        padding: 2rem 1rem;
        border-radius: 1rem;
        user-select: none;
        height: auto !important;
        margin: 30px 0;
    }

    .img_div_client__avatar {
        width: 99%;
        height: 210px;
        aspect-ratio: 1/1;
        overflow: hidden;
        border-radius: 2%;
        margin:1px;
        border: 0.4rem solid #8acd93;
    }


    .certificatediv {
        margin: 10px;
    }

    .certificatediv div img {
        width: 98%;
        height: 300px;
        border-radius: 4px;
        object-fit: contain;
    }
}