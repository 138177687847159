.ServiceFooter {
    margin: 50px 50px 0px 50px;
    padding: 40px 10px 10px 10px;
    border-top: 2px solid black;

}

.ServiceFooter ul {
    display: flex;
    justify-content: space-between;
    list-style: none;
    padding: 0;
}

.ServiceFooter ul li {
    font-size: 20px;
    font-weight: 500;
}

.ServiceFooter ul li a {
    text-decoration: none;
    color: rgb(58, 55, 55);
}

.Copyright_service {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 50px;
}

.Copyright_service p {
    font-size: 15px;
}

@media screen and (max-width:767px) {
    .ServiceFooter {
        margin: 10px;
        padding: 10px;

    }

    .ServiceFooter ul {
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
    }

    .ServiceFooter ul li {
        margin:  10px;
    }
}