
.event-packge-tabpanel {
    /* background-color: aliceblue; */
    /* height: 500px; */
    padding: 40px 0 70px 0;
    position: relative;
}
.event-packge-tabpanel::before {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-image: linear-gradient(rgba(255, 255, 255, 0.1), rgba(255, 255, 255, 0.1)), url('../assest/restaurantImg//Coffee.jpg');
    background-size: contain;
    background-repeat: repeat;
    opacity: 0.1;
    z-index: -44;
}

.event-packge-text .event-div-packge {
    display: block;
    position: relative;
}

.event-packge-text h2 {
    text-align: center;
    color: rgb(49, 45, 45);
    font-size: 32px;
    line-height: 40px;
    font-weight: 700;
    margin:30px 10px 20px 10px;
    letter-spacing: 1px;
}

.event-packages-plans {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    margin: 50px 0;
}

.event-nano-package {
    width: 380px;
    border: 1px solid rgb(185, 173, 173);
    border-radius: 20px;
    box-shadow: 1px 1px 4px 4px lightgrey;
    backdrop-filter: blur(25px);
    margin: 10px;
}
.event-nano-package .event-imgboxdiv {
   text-align: center;
    padding: 20px 0;
}

.event-nano-package .event-imgboxdiv h5{
    font-size: 22px;
    margin: 10px 30px 0 30px;
    font-weight: 600;
}
.event-nano-package .planbox{
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin:20px;
    border-bottom: 1px solid rgb(201, 191, 191);
}
.event-nano-package .planbox p{
    display: flex;
    align-items: center;
}
.event-nano-package .planbox p .icon{
    color: brown;
    margin-right: 4px;
}
.event-nano-package .planbox .pred{
    color: brown;
}
.event-nano-package .planbox .pred span{
    font-weight: bold;
    font-size: 26px;
    margin: 0 5px;
}
.event-packge-detail{
    margin-left: 20px;
}
.event-packge-detail div{
    display: flex;
}
.event-packge-detail p{
    color: rgb(68, 65, 65);
    margin: 5px;
}

.event-btn-div .gst-p {
    margin: 10px 30px;
    font-style: italic;
}

.event-btn-div button {
    margin: 30px 0;
    padding: 10px 40px;
    border-radius: 30px;
    font-weight: bold;
}


@media screen and (max-width:767px) {
    .event-packge-text h2 {
        font-size: 20px;
    margin: 10px;

    }

    .event-packages-plans {
        display: block;
        margin:30px 5px;
    }

    .event-nano-package {
        width: 100%;
        margin: 20px 0;
    }
    .event-packge-detail{
        margin-left: 50px;
        margin-top: 20px;
    }

}