
.pedhlagao_div{
    margin:50px;
}
.pedhlagao_div h3{
    font-weight: bold;
    letter-spacing: 0.3px;
}
.pedhlagao_div h3 span{
    color: #3aa348;
    
}
.pedhlagao_div div h5{
    font-size: 25px;
    font-weight: bold;
    margin:20px 0 0 0;

}
.faq_pedh{
    border:  3px solid #88bb8f;
    margin: 20px;
    border-radius: 2px;
    padding: 20px;
}
.faq_pedh h5{
    font-weight: bold;
    letter-spacing: 0.4px;
    color: rgb(69, 66, 66);
}
@media screen and (max-width:767px) {
    .pedhlagao_div{
        margin:50px 20px;
    }
    .pedhlagao_div h3{
        font-size: 22px;

    }
    .pedhlagao_div div h5{
        font-size: 20px;
    
    }
}