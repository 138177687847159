.Architecture_topmaindiv {
    margin-bottom: 15%;
}

.Architecture_topmaindiv img {
    width: 100%;
    height: 100vh;
    object-fit: cover;
    margin-bottom: 0px;
    filter: brightness(40%);
}

.Architecture_topmaindiv .Architecture_head {
    color: white;
    margin-top: -35%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    filter: brightness(100%);

}

.Architecture_topmaindiv .Architecture_head h1 {
    font-weight: bold;
    font-size: 70px;
    letter-spacing: 2px;
    text-transform: uppercase;
    text-align: center;
}

.Architecture_topmaindiv .Architecture_head p {
    font-weight: bold;
    font-size: 20px;
    letter-spacing: 2px;
    margin: 20px 10%;
    text-align: center;
}

.TravelOptions {
    background-color: rgb(224, 219, 219);
    color: #1c1b1b;
    padding: 130px 5px 50px 5px;
    text-align: center;
}

.TravelOptions_slide {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}


.TravelOptions_slide a {
    text-decoration: none;
}


.TravelOptions_life_Slider {
    margin: 20px;
    width: 390px;
    text-align: justify;
    border: 5px solid white;
    border-radius: 40px;
    background-color: white;
    color: #1c1b1b;
    padding-bottom: 10px;
    box-shadow: 0 0.25rem 1.125rem rgba(75, 70, 92, 0.1);

}

.TravelOptions_life_Slider img {
    height: 70vh;
    width: 100%;
    object-fit: cover;
    border-radius: 35px 35px 0 0;
}

.TravelOptions_life_Slider h4 {
    margin: 0;
    padding: 10px 0;
    font-weight: 700;
    font-size: 20px;
    text-align: center;

}



.Internationally {
    text-align: center;
    background-color: rgb(235, 233, 233);
    color: #1c1b1b;
    padding: 50px 80px;

}

.Internationally h1 {
    font-weight: bold;
}

.Internationally p {
    letter-spacing: 1px;
    font-size: 17px;
    margin-top: 40px;
}

.Internationally .Learnbtn {
    background: linear-gradient(to right, #8c5fff, #f17bfe);
    color: white;
    text-transform: capitalize;
    border: 1px solid white;
    font-size: 16px;
    padding: 8px 60px;
    margin-top: 40px;

}


/* fff */

.Background-fixed {
    background-attachment: fixed;
    background-position: center;
}

.ImageBackgroundWrap {
    position: relative;
    z-index: 0;
    background-size: cover;
    background-repeat: no-repeat;
    background-image: url('../assest/architecture/inquire-custom-01.jpg');
}

.ImageInnerWrap {
    /*  position: relative;
    z-index: 223;*/
    max-width: 100%;
    margin: 0 auto;

}

.TextBackground {
    /* background-color: rgba(0, 14, 45, 0.83); */
    padding: 13% 2%;
    color: white;
    margin-right: 0;
    text-align: center;
}

.left_Architectural {
    padding: 60px;
}

.left_Architectural h1 {
    font-weight: bold;
}

.left_Architectural p {
    letter-spacing: 1px;
    font-size: 17px;
}

.left_Architectural .btnb {
    color: white;
    text-transform: capitalize;
    border: 1px solid white;
    font-size: 16px;
    padding: 10px 60px;
    margin-top: 40px;
    border-radius: 40px;
}

.left_Architectural .btnb:hover {
    background: linear-gradient(to right, #8c5fff, #f17bfe);
    color: white;

}

.LatestCustom_welcomeDiv {
    text-align: center;
    padding: 20px 0;
    background-color: rgb(235, 233, 233);
    color: #1c1b1b;
}


.LatestCustomhome {
    display: flex;
    justify-content: space-around;
    margin: 20px 0;
}


.LatestCustomhome .imggdiv {
    padding: 10px 50px;

}

.LatestCustomhome .imggdiv img {
    border-radius: 6px;
    width: 100%;
    height: 400px;
    object-fit: cover;
}

.LatestCustomhome .textdiv {
    padding: 10px 50px;
    text-align: start;
}

.LatestCustomhome .textdiv h6 {
    font-size: 30px;
    font-weight: bold;
    /* border-bottom: 1px solid lightgray; */
    color: #1c1b1b;
    margin-top: 10px;
    margin-bottom: 10px;
    padding-bottom: 10px;
}

.LatestCustomhome .textdiv p {
    font-size: 17px;
    margin: 0px;
    letter-spacing: 1px;
    color: #1c1b1b;
    padding-top: 20px;
    text-align: justify;

}

.LatestCustomhome .textdiv .morebtn {
    background: linear-gradient(to right, #8c5fff, #f17bfe);
    color: white;
    text-transform: capitalize;
    font-size: 16px;
    padding: 8px 30px;
    margin-top: 40px;
}

.LatestCustomhome .textdiv div a {
    text-decoration: none;
    display: flex;
    align-items: center;
    color: #1c1b1b;
    border-top: 1px solid rgb(131, 126, 126);
    padding: 10px;
    margin: 30px 0;
}

.Custom_topdiv {
    margin-bottom: 15%;
}

.Custom_topdiv img {
    width: 100%;
    height: 100vh;
    object-fit: cover;
    margin-bottom: 0px;
    filter: brightness(40%);
}

.Custom_topdiv .custom_head {
    color: white;
    margin-top: -25%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    filter: brightness(100%);

}

.Custom_topdiv .custom_head h1 {
    font-weight: bold;
    font-size: 40px;
    letter-spacing: 2px;
}

.Custom_topdiv .custom_head p {
    font-weight: bold;
    font-size: 20px;
    letter-spacing: 2px;
    margin: 20px 10%;
    text-align: center;
}

.Custom_topdiv button {
    background: linear-gradient(to right, #8c5fff, #f17bfe);
    color: white;
    text-transform: capitalize;
    font-size: 16px;
    padding: 8px 30px;
    margin-top: 40px;
}

.alldivbtn {
    display: flex;
    justify-content: center;
    align-items: center;
}

.alldivbtn button {
    background: linear-gradient(to right, #8c5fff, #f17bfe);
    color: white;
    text-transform: capitalize;
    font-size: 16px;
    padding: 8px 30px;
    margin-top: 40px;
}

.contact_Architectural {
    margin-bottom: 13%;
}

.contact_Architectural img {
    width: 100%;
    height: 70vh;
    object-fit: cover;
    margin-bottom: 0px;
    filter: brightness(40%);
}

.contact_Architectural .contact_architechead {
    color: white;
    margin-top: -20%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    filter: brightness(100%);
    text-align: center;

}

.contact_Architectural .contact_architechead h2 {
    font-weight: bold;
    font-size: 40px;
    letter-spacing: 2px;
    text-transform: uppercase;
}

.contact_Architectural_form {
    padding: 50px;
}

@media screen and (max-width:767px) {
    .Architecture_topmaindiv img {
        height: 350px;
    }

    .Architecture_topmaindiv .Architecture_head {
        margin-top: -62%;

    }

    .Architecture_topmaindiv .Architecture_head h1 {
        font-size: 26px;
    }

    .Architecture_topmaindiv .Architecture_head p {
        font-size: 10px;
        margin-top: 10px;
        padding: 0 10px;
    }

    .TravelOptions {
        padding: 20px 0px;
    }

    .TravelOptions_slide {
        padding: 10px;
    }

    .TravelOptions_slide h1 {
        font-size: 18px;
    }

    .TravelOptions_life_Slider {
        width: 340px;
    }

    .TravelOptions_life_Slider img {
        height: 250px;
        width: 100%;
    }

    .LatestCustomhome .imggdiv {
        padding: 10px 20px;

    }

    .LatestCustomhome .imggdiv img {
        height: 300px;
    }

    .LatestCustomhome .textdiv {
        padding: 10px 20px;
    }

    .LatestCustomhome .textdiv h6 {
        font-size: 30px;
    }

    .Custom_topdiv img {
        height: 400px;
    }

    .Custom_topdiv .custom_head {
        margin-top: -62%;

    }

    .Custom_topdiv .custom_head h1 {
        font-size: 26px;
    }

    .Custom_topdiv .custom_head p {
        font-size: 10px;
        margin-top: 10px;
        padding: 0 10px;
    }

    .Internationally {
        padding: 40px 20px;

    }

    .Internationally p {
        letter-spacing: 1px;
        text-align: justify;
        font-size: 17px;
    }

    .Internationally .Learnbtn {
        margin-top: 30px;
    }

    .left_Architectural {
        padding: 60px 20px;
    }

    .left_Architectural p {
        text-align: justify;
    }

    .contact_Architectural {
        margin-bottom: 18%;
    }

    .contact_Architectural img {
        height: 350px;
    }

    .contact_Architectural .contact_architechead {
        margin-top: -62%;
        padding: 0 20px;

    }

    .contact_Architectural .contact_architechead h2 {
        font-size: 20px;
    }

    .contact_Architectural_form {
        padding: 50px 0px;
    }

}