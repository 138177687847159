.VentureDetaildiv img{
    width: 100%;
}

.venturebox_welcomeDiv {
    text-align: center;
    padding: 20px 0;
    background-color: #020202;
    color: white;
}
.venturebox_welcomeDiv .imgg{
    width: 100px;
    margin: 20px 0;
}

.venturebox_home {
    display: flex;
    justify-content: space-around;
    margin: 20px 0;

}


.venturebox_home .imggdiv {
    padding: 10px 50px;

}

.venturebox_home .imggdiv img {
    border-radius: 6px;
    width: 100%;
    height: 500px;
    object-fit: cover;
}

.venturebox_home .textdiv {
    padding: 10px 50px;
    text-align: start;

}

.venturebox_home .textdiv h6 {
    font-size: 40px;
    font-weight: bold;
    /* border-bottom: 1px solid lightgray; */
    color: yellow;
    margin-top: 10px;
    margin-bottom: 10px;
    padding-bottom: 10px;
}

.venturebox_home .textdiv p {
    font-size: 17px;
    margin: 0px;
    letter-spacing: 1px;
    color: white;
    padding-top: 20px;
    text-align: justify;

}
.venturedivimages{
    margin: 40px;
}
.venturedivimages div img{
    width: 100%;
    height: 300px;
    border-radius: 4px;
    object-fit: cover;
    border: 3px solid gray;
    box-shadow: 0 0.25rem 1.125rem rgba(75, 70, 92, 0.1);
}

.catgdiv{
    padding:20px 50px;
}
.ownedbydiv{
    padding:20px 50px;

}
.ownedbydiv h1{
    font-size: 24px;
    font-weight: bold;
    padding: 20px 0;

}

.ownedbydiv div img{
    width: 100%;
    height: 250px;
    object-fit: cover;
    border: 3px solid gray;
    box-shadow: 0 0.25rem 1.125rem rgba(75, 70, 92, 0.1);
    border-radius: 4px;

}

.releasepressdiv{
    box-shadow: 0 0.25rem 1.125rem rgba(75, 70, 92, 0.1);
    border-radius: 4px;
    border: 3px solid gray;
    padding: 40px 0;
}
.releasepressdiv div img{
    width: 100%;
    height: 200px;
    object-fit: cover;
    border: 1px solid lightgray;
    box-shadow: 0 0.25rem 1.125rem rgba(75, 70, 92, 0.1);
    border-radius: 4px;

}
.pressrelease h1{
    margin: 0;
    padding: 3px;
}