.upcomingDiv_volunteer{
    display: flex;
    justify-content: space-between;
    margin:20px 10px 20px 0;

    
}
.upcomingDiv_volunteer div img{
    width: 135px;
    height: 90px;
    object-fit: cover;
    border-radius: 4px;
    margin: 0 20px;
}
.upcomingDiv_volunteer div h6{
    font-weight: bold;
}
.upcomingDiv_volunteer div p{
    font-size: 13px;
    margin-bottom: 2px;
}
.upcomingDiv_volunteer div .loc{
    color: gray;
    font-size: 11px;
}