.welcomeDiv {
    text-align: center;
    padding: 50px 0;
}

.welcomeDiv h1 {
    font-weight: bold;
    letter-spacing: 3px;
}

.welcomeDiv h1 span {
    color: #3aa348;
}

.welcomeDiv h6 {
    font-weight: bold;
    letter-spacing: 1px;
    font-size: 20px;
    color: rgb(47, 45, 45);
}

.welcomeDiv p {
    margin: 20px 50px;
    text-align: justify;
    font-size: 18px;
    letter-spacing: 1px;
}

.welcomeDiv button {
    background-color: #3aa348;
    color: white;
    letter-spacing: 2px;
    padding: 10px 20px;
    margin: 20px 0;
}

.welcomeDiv button:hover {
    background-color: #3aa348;
    color: white;
}

.NGOTrustHome {
    display: flex;
    justify-content: space-around;
    margin: 20px 0;
    background-color: #3aa348;
}


.NGOTrustHome .ngo_imggdiv {
    padding: 50px;

}

.NGOTrustHome .ngo_imggdiv img {
    border-radius: 6px;
    width: 100%;
    height: 400px;
    object-fit: cover;
}

.NGOTrustHome .NGO_textdiv {
    padding: 50px;

}

.NGOTrustHome .NGO_textdiv h1 {
    font-size: 46px;
    font-weight: bold;
    color: white;
    border-bottom: 3px solid white;
    padding-bottom: 10px;
    letter-spacing: 2px;
}

.NGOTrustHome .NGO_textdiv h6 {
    font-size: 22px;
    font-weight: bold;
    color: white;
    margin-top: 30px;
    margin-bottom: 10px;
}



.NGOTrustHome .NGO_textdiv p {
    font-size: 17px;
    margin-bottom: 20px;
    letter-spacing: 1px;
    color: rgb(255, 255, 255);
    padding-top: 20px;
    text-align: justify;

}

.NGOTrustHome .NGO_textdiv button {
    background-color: white;
    border: 1px solid #3aa348;
    color: #3aa348;
    padding: 10px 30px;
    font-weight: bold;
    letter-spacing: 2px;
    font-size: 18px;
    margin: 20px 0;
}

.NGOTrustHome .NGO_textdiv button:hover {
    background-color: white;
    color: #3aa348;
}


.ngo_ourwork_detail {
    display: flex;
    flex-wrap: wrap;
    margin-right: 0;
}

.linktag_ngo {
    text-decoration: none;
}

.ngo_ourwork_area {
    background-color: #3aa348;
    width: 300px;
    margin: 20px 10px;
    border-radius: 6px;
    text-align: center;
}

.ngo_ourwork_area div img {
    width: 270px;
    height: 180px;
    object-fit: cover;
    border-radius: 3%;
    margin: 20px 0;

}

.ngo_ourwork_area div h5 {
    font-size: 20px;
    font-weight: bold;
    padding-bottom: 10px;
    color: white;
}

.ngo_ourwork_area div button {
    background-color: white;
    border: 1px solid #3aa348;
    color: #3aa348;
    padding: 8px 20px;
    font-weight: bold;
    letter-spacing: 1px;
    font-size: 15px;
    margin: 10px 0 20px 0;
}

.ngo_ourwork_area div button:hover {
    background-color: white;
    color: #3aa348;
}

.GreenYatra_btndiv {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 30px 0;
}

.GreenYatra_btndiv button {
    background-color: #3aa348;
    color: white;
    padding: 10px 20px;
    font-size: 18px;

}

.GreenYatra_btndiv button:hover {
    background-color: #3aa348;
    color: white;
}

.workshopsdiv {
    width: 100%;
    border-radius: 6px;
}

.workshopsdiv .img_workdiv {
    display: flex;
    justify-content: center;
    align-items: center;
}

.workshopsdiv .img_workdiv img {
    width: 85%;
    height: 280px;
    object-fit: cover;
    border-radius: 3%;
    margin: 20px;

}

.workshopsdiv div h5 {
    font-size: 20px;
    font-weight: bold;
    padding-bottom: 10px;
    color: black;
    margin: 20px 50px;

}

.workshopsdiv div p {
    font-size: 20px;
    padding-bottom: 10px;
    color: black;
    text-align: justify;
    margin: 20px 50px;
}

.workshopsdiv div button {
    background-color: #3aa348;
    border: 1px solid #3aa348;
    color: white;
    padding: 8px 20px;
    font-weight: bold;
    letter-spacing: 0px;
    font-size: 14px;
    text-transform: capitalize;
    margin: 10px 20px 20px 50px;
}

.workshopsdiv div button:hover {
    background-color: #3aa348;
    color: white;
}

.container_impact {
    background-image: url('../assest/seed.webp');
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    height: 650px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 30px 0;
}

.funfact img {
    width: 100px;
}

.our_partner .slick-dots li button {
    width: 20px;
}

.ngocontact {
    display: flex;

}

.ngo_icondiv {
    margin-right: 10px;
}

.ngo_icondiv p {
    background-color: #3aa348;
    color: white;
    border-radius: 50%;
}

.subscribebox {
    background-image: url('../assest/bgtreee.webp');
    padding: 20px 50px;
}

.subscribediv {
    padding: 20px 0;
    color: white;
}

.subscribediv h1 {
    font-size: 23px;
}

.subscribediv h6 {
    padding: 10px;
    font-size: 17px;
}

.subscribediv h1 span {
    color: #3aa348;
}

@media only screen and (max-width: 767px) {

    .welcomeDiv h1 {
        font-size: 24px;
        letter-spacing: 1px;
    }

    .welcomeDiv h6 {
        font-weight: 500;
        font-size: 17px;
        margin: 20px;
    }

    .welcomeDiv p {
        margin: 20px 30px;
        font-size: 17px;
    }


    .NGOTrustHome {
        margin: 0px;
    }

    .NGOTrustHome .ngo_imggdiv {
        padding: 20px;

    }

    .NGOTrustHome .ngo_imggdiv img {
        height: 250px;
    }

    .NGOTrustHome .NGO_textdiv {
        padding: 20px;

    }

    .NGOTrustHome .NGO_textdiv h1 {
        font-size: 36px;
    }

    .NGOTrustHome .NGO_textdiv h6 {
        font-size: 20px;
    }

    .ngo_ourwork_area {
        width: 95%;
        margin: 10px;
    }
    
    .ngo_ourwork_area div img {
        width: 88%;
        /* height: 180px;
        object-fit: cover;
        border-radius: 3%;
        margin: 20px 0; */
    
    }

    .GreenYatra_btndiv {
        margin: 30px 10px;
    }

    .GreenYatra_btndiv button {
        font-size: 17px;

    }

    .container_impact {
        height: auto;
        padding: 30px 0;
    }

    .container_impact .row {
        row-gap: 50px;
    }


    .subscribebox {
        background-image: url('../assest/bgtreee.webp');
        padding: 20px;
    }

    .smallscrenpadding {
        padding: 0;
    }

    .subscribediv {
        padding: 20px 0;
        color: white;
    }

    .subscribediv h1 {
        font-size: 23px;
    }

    .subscribediv h6 {
        padding: 10px;
        font-size: 17px;
    }

    .subscribediv h1 span {
        color: #3aa348;
    }

}