
.contact_topDiv img {
    width: 100%;
    height: 70vh;
    object-fit: cover;
    margin-bottom: 0px;
}


.contactdiv_location {
    display: flex;
    align-items: center;
    margin: 20px 0;

}

.contactdiv_location .ngo_icondiv {
    margin-right: 15px;
}

.contactdiv_location .ngo_icondiv p {
    background-color: #3aa348;
    color: white;
    border-radius: 50%;
}

.contactdiv_location h6 {
    margin: 0;
    font-weight: bold;
    font-size: 18px;
}

.contactdiv_location p {
    margin: 0;
    font-size: 18px;

}

.maqformdiv div input {
    height: 50px;
}



.btnbtnbtn {
    background: linear-gradient(to right, #3d257b, #572d5c);
    color: white;
    text-transform: capitalize;
    font-size: 20px;
    letter-spacing: 1px;
    padding: 8px 60px;
    margin: 20px 0;
    border-radius: 6px;
    border: none;
}



@media screen and (max-width:767px) {
    .contact_topDiv img {
        height: 250px;
    }
  
    .maqformdiv{
        padding: 0;
        width: 90%;
        margin-left: 10px;
    }
    .maqformdiv div{
        padding: 0;
    }
    .office_location{
        padding: 30px;
    }

}