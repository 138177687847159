.topSlider-divmain {
    /* padding: 10px 5px; */
    padding: 0;
}

.topSlider-divmain h1 {
    font-size: 24px;
    text-align: start;
    text-transform: uppercase;
}

.item-topimg img {
    height: 100vh;
    width: 100%;
    object-fit: cover;
    border-radius: 4px;
}

.slick-prev,
.slick-next {
    background-color: rgb(0, 0, 0) !important;
    color: black !important;
    width: 40px !important;
    height: 50px !important;
    /* border-radius: 10px !important; */
    z-index: 66 !important;
    box-shadow: 2px 1px 6px rgb(125, 85, 85) !important;
}

.slick-prev {
    border-radius: 0 6px 6px 0 !important;
    margin: 0 25px;
}

.slick-next {
    border-radius: 6px 0 0 6px !important;
    margin: 0 26px;
}

@media screen and (max-width:767px) {
    .topSlider-divmain {
        padding: 10px 10px;
        width: 100%;
    }

    .topSlider-divmain h1 {
        font-size: 18px;
    }

    .item-topimg img {
        height: 250px;
        width: 300px !important;
        margin-left: 10%;
    }

    .slick-prev,
    .slick-next {
        width: 24px !important;
        height: 30px !important;
    }

    .slick-prev {
        border-radius: 0 6px 6px 0 !important;
        margin: 0 15px;
    }

    .slick-next {
        border-radius: 6px 0 0 6px !important;
        margin: 0 15px;
    }
}

@media screen and (min-width: 768px) and (max-width: 992px) {
    .topSlider-divmain {
        padding: 10px 5px;
    }

    .item-topimg img {
        height: 250px;
        width: 200px;
        object-fit: contain;
        border-radius: 4px;
    }
}