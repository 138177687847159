
.FocusArea p{
    border-bottom: 1px solid lightgray;
    padding-bottom: 10px;
}
.FocusArea div img{
    width: 50px;
    height: 50px;
    border-radius: 25px;
    margin-right: 8px;
}