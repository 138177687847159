.imgg-runningslide-div {
    padding: 30px 30px 20px 30px;
    background-color: black;
    color: white;

}

.imgg-runningslide-div h1 {
    font-size: 20px;
    text-align: start;
    text-transform: uppercase;
}

.imgg-runningslide-div .divv-gallery {
    padding: 5px;
}

.sliderr-running-box {
    margin: 10px 10px;
    overflow: hidden;
}

.running-img-box {
    width: 100% !important;
    text-align: center;
    border-radius: 6px;
    margin-bottom: 10px;
    position: relative;
   /* border: 1px solid white; */
   animation: slideLikeTrain 70s linear infinite;
}

.running-img-box img {
    width: 90%;
    height: 300px;
    object-fit: contain;

}

@keyframes slideLikeTrain {
    0% {
        transform: translateX(0%);
    }
    100% {
        transform: translateX(-2200%);
    }

}

@media screen and (max-width:767px) {
    .imgg-runningslide-div {
        padding: 5px;
    }

    .imgg-runningslide-div h1 {
        font-size: 18px;
    }

    .running-img-box {
        width: 100% !important;
    }

    .running-img-box img {
        width: 90%;
        height: 250px;
        object-fit: contain;
    }
}

@media screen and (min-width: 768px) and (max-width: 992px) {
    .imgg-runningslide-div {
        padding: 30px 5px 20px 5px;
    }

    .sliderr-running-box {
        margin: 10px 5px;
    }

    /* .running-img-box {
        width: 100% !important;
    }

    .running-img-box img {
        width: 90px !important;
        height: 250px !important;
    } */

}