.MISFurniture_topdiv {
    margin-bottom: 15%;
}

.MISFurniture_topdiv img {
    width: 100%;
    height: 100vh;
    object-fit: cover;
    margin-bottom: 0px;
    filter: brightness(40%);
}

.MISFurniture_topdiv .MISFurniture_head {
    color: white;
    margin-top: -35%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    filter: brightness(100%);

}

.MISFurniture_topdiv .MISFurniture_head h1 {
    font-weight: bold;
    font-size: 70px;
    letter-spacing: 2px;
    text-transform: uppercase;
    text-align: center;
}

.MISFurniture_topdiv .MISFurniture_head p {
    font-weight: bold;
    font-size: 20px;
    letter-spacing: 2px;
    margin: 20px 10%;
    text-align: center;
}
.mis_ProjectDetails{
    background-color: rgb(195, 190, 190);
}
.mis_ProjectDetails .pro_head{
    font-weight: bold;
    text-align: center;
    color: #1c1b1b;
    padding: 20px 0;
    letter-spacing: 1px;
}
.mislogodiv{
    padding:0 0 50px 20px;
}
.mislogodiv img{
     width: 400px;
}

.mis_aboutus{
    padding: 5% 50px 50px 50px;
    background-color: white;
    color: #1c1b1b;
}
.mis_aboutus h1{
    font-weight: bold;
    text-align: center;
    margin: 30px 0;
    letter-spacing: 1px;
}
.mis_aboutus p{
    letter-spacing: 1px;
    font-size: 17px;
}
.mis_aboutus ol li{
    letter-spacing: 1px;
    font-size: 17px;
}
.mis_project_div {
    background-color: rgb(235, 233, 233);
    color: #1c1b1b;
    padding: 40px 5px;
    text-align: center;
}

.misproject_slide {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}


.misproject_slide a {
    text-decoration: none;
}

.mis_ProjectDetails_life_Slider {
    margin: 20px;
    width: 280px;
    border: 5px solid white;
    border-radius: 4px;
    background-color: white;
    padding-bottom: 10px;
    color: black;
    box-shadow: 0 0.25rem 1.125rem rgba(75, 70, 92, 0.1);

}

.mis_ProjectDetails_life_Slider img {
    height: 20vh;
    width: 90%;
    margin:10px auto;
    object-fit: contain;
    border-radius: 4px;
}

.mis_ProjectDetails_life_Slider h4 {
    margin: 20px 20px 0 20px;
    font-weight: 700;
    font-size: 18px;
  
}

.mis_project_div .explorebtn {
    background: linear-gradient(to right, #362366, #4a1050);
    color: white;
    text-transform: capitalize;
    font-size: 16px;
    padding: 8px 30px;
    margin-top: 40px;
}

.img019div{
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 40px 0;
}
.img019div img{
    width: 100%;
    border-radius: 4px;
}
@media screen and (max-width:767px) {
    .MISFurniture_topdiv img {
        height: 400px;
    }

    .MISFurniture_topdiv .MISFurniture_head {
        margin-top: -70%;

    }

    .MISFurniture_topdiv .MISFurniture_head h1 {
        font-size: 26px;
    }

    .MISFurniture_topdiv .MISFurniture_head p {
        font-size: 10px;
        margin: 10px;
        padding: 0 10px;
    }
    .misproject_slide {
        padding: 10px 10px;
        width: 100%;
    }

    .misproject_slide h1 {
        font-size: 18px;
    }
    .mis_ProjectDetails_life_Slider img {
        height: 250px;
        width: 100% !important;
        /* margin-left: 10%; */
    }
    .mislogodiv{
        padding: 30px 0px;
    }
    .mislogodiv img{
         width: 90%;
    }
}