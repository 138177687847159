
.whatimg{
    margin: 50px 0;
}
.whatimg ul{
    display: flex;
    justify-content: space-around;
    list-style: none;
}
.whatimg img{
    width: 170px;
    height: 150px;
    object-fit: cover;
}
@media screen and (max-width:767px) {
    .whatimg ul{
        flex-wrap: wrap;
    }
    .whatimg img{
        width: 90%;
        height: 150px;
        object-fit: cover;
        margin: 30px 0;
    }
}