.ngo_greentop{
    width: 400px;
    margin: 20px 10px;
    border-radius: 6px;
    box-shadow:  0 0.25rem 1.125rem rgba(75, 70, 92, 0.1);
}
.ngo_greentop div img {
    width: 98%;
    height: 260px;
    object-fit: cover;
    border-radius: 3%;
    margin: 20px 0;

}
.ngo_greentop div a {
    text-decoration: none;
}
.ngo_greentop div h5 {
    font-size: 16px;
    font-weight: 500;
    padding:20px 0;
    color: rgb(43, 41, 41);
}
.schedule_timediv ul{
    list-style: none;
    padding-left: 0;
}
.schedule_timediv ul li{
    display: flex;
    align-items: center;
    font-size: 13px;
    color: rgb(73, 71, 71);
}
.FilterbyCategory p{
    font-size: 19px;
    padding: 30px 0 0 0;
}
.FilterbyCategory ul{
    list-style: none;
    padding-left: 0;
    margin-right: 20px;
}

.FilterbyCategory ul li{
    border-bottom: 1px solid lightgray;
    padding: 10px 0;
}
.FilterbyCategory ul li a{
    text-decoration: none;
    color: inherit;
}

@media only screen and (max-width: 767px) {
    .ngo_greentop {
        width: 90%;
        margin: 20px 10px;
        border-radius: 6px;
        box-shadow:  0 0.25rem 1.125rem rgba(75, 70, 92, 0.1);
    }
    .ngo_greentop div img {
        width: 96%;
        height: 180px;
        object-fit: cover;
        border-radius: 3%;
        margin: 20px 0;
    
    }
    .FilterbyCategory{
        margin: 20px;
    }
}