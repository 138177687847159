.logistic_topdiv {
    text-align: center;
    width: 800px;
    margin: auto;
    padding: 50px 0;
}

.logistic_topdiv h1 {
    font-size: 40px;
    font-weight: 600;
    margin-bottom: 40px;
}

.logistic_topdiv p {
    font-size: 16px;
}

.logistic_topdiv button {
    background-color: #4171d2;
    border-radius: 6px;
    font-size: 17px;
    padding: 8px 30px;
    color: white;
    margin-top: 40px;

}

.logistic_topdiv button:hover {
    background-color: #15429c;
    color: white;
}

.marinemaindiv {
    margin-top: -36%;
    margin-bottom: 13%;
}

.logistic_experience img {
    width: 100%;
    height: 100vh;
    object-fit: cover;
    margin-bottom: 0px;
    filter: brightness(20%);
}


.logistic_experience .logisticexperience_detail {
    color: white;
    display: flex;
    flex-direction: column;
    justify-content: center;
    /* align-items: center; */
    filter: brightness(100%);

}

.logistic_experience .logisticexperience_detail h1 {
    font-weight: bold;
    font-size: 60px;
    letter-spacing: 2px;
    padding: 0 15%;
}

.logistic_experience .logisticexperience_detail p {
    font-weight: bold;
    font-size: 20px;
    letter-spacing: 2px;
    margin-top: 20px;
    text-align: center;
    padding: 0 15%;

}

.logistic_experience .logisticexperience_detail button {
    font-size: 18px;
    font-weight: 500;
    padding: 10px 30px;
    margin-top: 20px;
    background-color: aliceblue;
    color: black;
}

.logistic_experience .logisticexperience_detail button:hover {
    background-color: aliceblue;
    color: black;
}

.logistic_clients {
    text-align: center;
    width: 90%;
    margin: auto;
    padding: 50px 0;
}

.logistic_clients h1 {
    font-size: 40px;
    font-weight: 600;
    margin-bottom: 40px;
}

.logistic_clients p {
    font-size: 16px;
}


.sectors_weworkIn {
    margin: 0px 0;
    background-color: rgb(235, 233, 233);
    color: #1c1b1b;
    padding: 40px 0;
    
}

.sector_alldetail {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin-right: 0;
}

.sector_alldetail a {
    color: white;
}

.linktag_sector {
    text-decoration: none;
    border: 1px solid lightgray;
    border-radius: 4px;
    padding: 0;
    background-color: white;
    color: #1c1b1b;
}


.sector_areadiv {
    width: 100%;
    margin: 20px 0px;
    border-radius: 6px;
}

.sector_areadiv div img {
    width: 100%;
    height: 280px;
    object-fit: cover;
    border-radius: 1% 1% 0 0;
    margin: -20px 0 20px 0;

}

.sector_areadiv div h6 {
    font-weight: 600;
    padding: 0 20px;
    color: #1c1b1b;
    font-size: 24px;
    margin: 0 0 10px 0;

}

.sector_areadiv div p {
    padding: 0 20px;
    color: #1c1b1b;
    font-size: 17px;

}

.ViewServices_btn {
    display: flex;
    justify-content: center;
    margin: 40px 0;
}

.ViewServices_btn button {
    font-size: 18px;
    font-weight: 500;
    padding: 10px 30px;
    margin-top: 20px;
    background-color: aliceblue;
    color: black;
}

.ViewServices_btn button:hover {
    background-color: aliceblue;
    color: black;
}



.CustomisedSolutions_div {
    margin: 10px 50px;
}

.our_solutioncustomize {
    width: 100%;
    border-radius: 6px;
}

.our_solutioncustomize .iconimgdiv {
    margin: 10px 30px;
}

.our_solutioncustomize .iconimgdiv img {
    width: 100px;
    height: 80px;
    object-fit: contain;
    border-radius: 3px;
    margin: 0 20px 0 20px;

}

.our_solutioncustomize div h5 {
    font-size: 20px;
    font-weight: bold;
    color: black;
    margin: 20px 50px 0 50px;
    letter-spacing: 1px;
}

.our_solutioncustomize div p {
    font-size: 18px;
    padding-bottom: 10px;
    color: black;
    text-align: justify;
    margin: 20px 50px;
}

.contactSolutions_btn {
    display: flex;
    justify-content: center;
    margin: 40px 0;
}

.contactSolutions_btn button {
    font-size: 18px;
    font-weight: 500;
    padding: 10px 30px;
    margin-top: 20px;
    background-color: #4171d2;
    color: white;
}

.contactSolutions_btn button:hover {
    background-color: #4171d2;
    color: white;
}

@media screen and (max-width:767px) {
    .logistic_topdiv {
        width: 100%;
        padding: 20px;
    }

    .logistic_topdiv h1 {
        font-size: 26px;
    }

    .logistic_topdiv p {
        font-size: 14px;
    }

    .linktag_sector {
        width: 90%;
    }

    .WarehousingLogistic {
        padding-left: 50px;
    }

    .logistic_experience img {
        height: 160vh;
    }

    .marinemaindiv {
        margin-top: -185%;
        margin-bottom: 24%;
    }

    .logistic_experience .logisticexperience_detail h1 {
        font-size: 22px;
        padding: 0 15%;
    }

    .logistic_experience .logisticexperience_detail p {
        font-weight: 400;
        font-size: 16px;
        margin-top: 10px;
        padding: 0 15%;

    }


    .CustomisedSolutions_div {
        margin: 10px 20px;
    }

    .our_solutioncustomize {
        text-align: center;
    }

    .our_solutioncustomize .iconimgdiv {
        margin: 10px;
    }

    .our_solutioncustomize .iconimgdiv img {
        margin: 0;

    }

    .our_solutioncustomize div h5 {
        margin: 20px 20px 0 20px;
    }

    .our_solutioncustomize div p {
        font-size: 16px;
        margin: 20px;
    }

    .logistic_clients h1 {
        font-size: 26px;
    }


}