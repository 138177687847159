.finance_topdiv img {
    width: 100%;
    height: 100vh;
    object-fit: cover;
    margin-bottom: 0px;
    filter: brightness(20%);
}

.finance_topdiv .the_finance {
    color: white;
    margin-top: -28%;
    margin-bottom: 13%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    filter: brightness(100%);

}

.finance_topdiv .the_finance h1 {
    font-weight: bold;
    font-size: 50px;
    letter-spacing: 2px;
    padding: 0 15%;
    text-align: center;
    text-transform: uppercase;
}

.finance_topdiv .the_finance p {
    font-weight: bold;
    font-size: 20px;
    letter-spacing: 2px;
    margin-top: 20px;
    text-align: center;
    padding: 0 15%;

}

.finance_topdiv .the_finance button {
    font-size: 18px;
    font-weight: 500;
    padding: 10px 30px;
    margin-top: 20px;
    background-color: aliceblue;
    color: black;
}

.finance_topdiv .the_finance button:hover {
    background-color: aliceblue;
    color: black;
}

.Finance_welcomeDiv {
    text-align: center;
    padding: 50px 0;
}

.Finance_welcomeDiv h1 {
    font-weight: bold;
    font-size: 30px;
    letter-spacing: 2px;
    color: rgb(47, 45, 45);
    border-bottom: 2px solid rgb(126, 122, 122);
    padding-bottom: 20px;
    margin: 10px 50px;
}

.Finance_welcomeDiv h6 {
    font-weight: bold;
    letter-spacing: 1px;
    font-size: 20px;
    color: rgb(47, 45, 45);
}

.Finance_welcomeDiv p {
    margin: 20px 50px;
    font-size: 18px;
    letter-spacing: 1px;
}

.CapitalFinanceHome {
    display: flex;
    justify-content: space-around;
    margin: 20px 0;
}


.CapitalFinanceHome .imggdiv {
    padding: 50px;

}

.CapitalFinanceHome .imggdiv img {
    border-radius: 6px;
    width: 100%;
    height: 400px;
    object-fit: cover;
}

.CapitalFinanceHome .textdiv {
    padding: 50px;

}

.CapitalFinanceHome .textdiv h6 {
    font-size: 22px;
    font-weight: bold;
    border-bottom: 1px solid lightgray;
    color: black;
    margin-top: 30px;
    margin-bottom: 10px;
    padding-bottom: 10px;
}



.CapitalFinanceHome .textdiv p {
    font-size: 17px;
    margin: 0px;
    letter-spacing: 1px;
    color: rgb(103, 100, 100);
    padding-top: 20px;
    text-align: justify;

}


.our_workdiv_finance {
    width: 100%;
    border-radius: 6px;
}

.our_workdiv_finance .img_div {
    display: flex;
    justify-content: center;
    align-items: center;
}

.our_workdiv_finance .img_div a img {
    width: 85%;
    height: 280px;
    object-fit: cover;
    border-radius: 3%;
    margin: 0 20px 0 20px;

}

.our_workdiv_finance div h5 {
    font-size: 24px;
    font-weight: bold;
    padding-bottom: 10px;
    color: black;
    margin: 20px 50px;
    letter-spacing: 1px;
    border-bottom: 1px solid lightgray;
}

.our_workdiv_finance div p {
    font-size: 18px;
    padding-bottom: 10px;
    color: black;
    text-align: justify;
    margin: 20px 50px;
}

.our_workdiv_finance div button {
    background-color: #3a59a3;
    border: 1px solid #3a59a3;
    color: white;
    padding: 8px 20px;
    font-weight: bold;
    letter-spacing: 0px;
    font-size: 14px;
    text-transform: capitalize;
    margin: 5px 20px 20px 50px;
}

.our_workdiv_finance div button:hover {
    background-color: #223560;
    color: white;
}

.providedfinancial {
    text-align: center;
    padding: 50px 0;
    background-color: #223560;
    color: white;
    margin-top: 50px;

}

.providedfinancial h1 {
    font-size: 30px;
    font-weight: 700;
    letter-spacing: 1px;
    margin: 0;
}

.providedfinancial div {
    display: flex;
    align-items: center;
    justify-content: center;
}

.providedfinancial button {
    font-size: 18px;
    font-weight: 500;
    padding: 10px 30px;
    margin-left: 30px;
    background-color: aliceblue;
    color: black;
}

.providedfinancial button:hover {
    background-color: aliceblue;
    color: black;
}

.servicesFinance_div {
    margin: 10px 0;
}

.our_services_finance {
    width: 100%;
    border-radius: 6px;
}

.our_services_finance .logoimgdiv {
    margin: 10px 30px;
}

.our_services_finance .logoimgdiv img {
    width: 100px;
    height: 80px;
    object-fit: contain;
    border-radius: 3px;
    margin: 0 20px 0 20px;

}

.our_services_finance div h5 {
    font-size: 20px;
    font-weight: bold;
    color: black;
    margin: 20px 50px 0 50px;
    letter-spacing: 1px;
}

.our_services_finance div p {
    font-size: 18px;
    padding-bottom: 10px;
    color: black;
    text-align: justify;
    margin: 20px 50px;
}

@media screen and (max-width:767px) {
    .finance_topdiv img {
        height: 460px;
    }

    .finance_topdiv .the_finance {
        margin-top: -98%;
        margin-bottom: 8%;

    }

    .finance_topdiv .the_finance h1 {
        font-size: 20px;
        padding: 10px;
    }

    .finance_topdiv .the_finance p {
        font-size: 14px;
        margin-top: 10px;
        padding: 10px;
        font-weight: bold;
    }

    .finance_topdiv .the_finance button {
        margin-top: 10px;
    }

    .Finance_welcomeDiv h1 {
        font-size: 20px;
        padding-bottom: 20px;
        margin: 10px;
    }

    .Finance_welcomeDiv h6 {
        font-size: 18px;
    }

    .Finance_welcomeDiv p {
        margin: 20px;
        font-size: 17px;
        text-align: justify;
    }

    

    .CapitalFinanceHome {
        margin: 0px;
    }

    .CapitalFinanceHome .imggdiv {
        padding: 20px;

    }

    .CapitalFinanceHome .imggdiv img {
        height: 250px;
    }

    .CapitalFinanceHome .textdiv {
        padding: 20px;

    }

    .CapitalFinanceHome .textdiv h6 {
        font-size: 20px;
    }

    .providedfinancial {
        padding: 30px 0;
        background-color: #223560;
        color: white;
        margin-top: 50px;
        width: 100%;
    
    }
    
    .providedfinancial h1 {
        font-size: 20px;
        padding: 0 20px;
    }

   
}