.agriLogistic_div {
    padding: 30px 50px;
}

.agriLogistic_topdiv {
    text-align: center;
    width: 800px;
    margin: auto;
    padding: 50px 0;
}

.agriLogistic_topdiv h1 {
    font-size: 40px;
    font-weight: bold;
    margin-bottom: 40px;
}

.agriLogistic_topdiv p {
    font-size: 16px;
}

.OurStrength h4 {
    text-align: center;
    font-weight: bold;
    letter-spacing: 0.3px;
    margin: 20px 0;
}

.about_rowdetail {
    display: flex;
    justify-content: space-evenly;
    flex-wrap: wrap;
    align-items: center;
}

.about_rowdetail .agri_col12 {
    width: 300px;
    height: 330px;
    border: 1px solid rgb(243, 238, 238);
    border-radius: 6px;
    margin: 10px;
    display: flex;
    justify-content: center;
    box-shadow:  0 0.25rem 1.125rem rgba(75, 70, 92, 0.1);
    background-color: #fff;
}

.our_agrilogisticdiv {
    width: 100%;
}

.our_agrilogisticdiv .iconimgdiv {
    margin: 20px;
    display: flex;
    justify-content: center;
    align-items: center;

}

.our_agrilogisticdiv .iconimgdiv img {
    width: 100px;
    height: 80px;
    object-fit: contain;
    border-radius: 3px;
    margin: 10px 0 ;

}

.our_agrilogisticdiv div h5 {
    font-size: 20px;
    font-weight: 500;
    color: black;
    margin: 20px;
    letter-spacing: 1px;
    text-align: center;
}

.our_agrilogisticdiv div p {
    font-size: 16px;
    margin: 20px;
    text-align: center;

}
.Contactsubtlediv{
    padding: 50px;
}
.TouchContact{
    background-color: #fff;
    border-radius: 6px;
    padding: 50px;
    margin-right: 40px;
}

.messagebtn {
    background: linear-gradient(to right, #3d257b, #572d5c);
    color: white;
    text-transform: capitalize;
    font-size: 18px;
    letter-spacing: 1px;
    padding: 8px 60px;
    margin: 20px 0;
    border-radius: 6px;
    border: none;
}

@media screen and (max-width:767px) {
    .agriLogistic_div {
        padding: 20px 10px;
    }
    .agriLogistic_topdiv {
        width: 90%;
    }
    .agriLogistic_topdiv h1 {
        font-size: 30px;
    }
    
    .agriLogistic_topdiv p {
        text-align: justify;
    }
    .Contactsubtlediv{
        padding: 30px;
    }
    .rowpaddd{
        padding: 0;
        justify-content: center;
        margin-left: 20px;
    }
    .padingdiv{
        padding: 0;
    }
    .padingdiv div{
        padding: 0;
    }
    .TouchContact{
        padding: 20px 10px;
        margin: 5px;
    }
    .messagebtn {
        font-size: 14px;
    }
    
}