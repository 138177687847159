.People{
    margin: 40px 0;
}
.People p{
    font-size: 16px;
    color: gray;
}
.People h1{
    font-weight: bold;
}
.profiletag{
    display: flex;
    justify-content: space-around;
    width: 300px;
    margin: 50px 0;
}
.profiletag div img{
    width: 70px;
    height: 70px;
    border-radius: 50%;
}
.profiletag div p{
    margin-bottom: 2px;
    font-size: 14px;
    font-weight: 500;
}
.profiletag div p span{
    color: gray;
}

.profiletag div p a{
    color:  rgb(184, 33, 33);
    text-decoration: none;
}
.profiletag div p a:hover{
    text-decoration: underline;
}

.DetailPage_div img{
    width: 100%;
    height: 500px;
    border-radius: 4px;
    object-fit: cover;
}
.DetailPage_div p{
    margin: 20px 0;
    font-size: 19px;
    text-align: justify;
}

.DetailPage_div h6{
    font-size: 22px;
    font-style: italic;
    color: rgb(42, 40, 40);
    
}