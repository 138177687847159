.bgblackdiv_OurChairman{
    background-color: rgb(235, 233, 233);
    padding: 50px;
}
.OurChairman_Imgdiv {
    /* border: 4px solid black; */
    border-radius: 40px;
    box-shadow: 0 0.25rem 1.125rem rgba(75, 70, 92, 0.1);
    background-color: white;
    padding: 0;
}
.OurChairman_Imgdiv  .imgdiv{
    display: flex;
    justify-content: center;
}
.OurChairman_Imgdiv img{
    width: 470px;
    height: 470px;
    object-fit: cover;
    border-radius: 50%;
    border: 10px solid rgb(113, 110, 110);
    margin-top: 30px;
    background-color: rgb(252, 249, 249);

}
.OurChairman_Imgdiv  div{
    padding: 20px 40px;
    text-align: center;
}

.OurChairman_Imgdiv h1{
    font-weight: bold;
    font-size: 30px;
}
.OurChairman_Imgdiv h6{
    padding: 10px 0;
    font-weight: bold;
}


.OurChairman_Detaildiv{
    color: black;
    padding: 10px 40px;
}

.OurChairman_Detaildiv h1{
    font-weight: bold;
}
.OurChairman_Detaildiv h6{
    padding: 20px 0;
}
.OurChairman_Detaildiv p{
    padding-top: 20px;
    letter-spacing: 1px;
    font-size: 17px;
}

.OurChairman_iconsocial{
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}
.OurChairman_iconsocial a {
    margin-right: 19px;
}
.OurChairman_iconsocial a .icoimgsoc{
    width: 30px;
    height: 30px;
    object-fit: contain;
    border-radius: 0%;
    border: unset;
    margin-top: 20px;
}

@media only screen and (max-width: 767px) {
    .bgblackdiv_OurChairman{
        padding: 50px 30px;
    }
    .OurChairman_Imgdiv img{
        width: 90%;
        height: 90%;
    
    }
    .OurChairman_Imgdiv  div{
        padding: 20px;
    }
    .OurChairman_Imgdiv h1{
        font-size: 20px;
    }
   
    .OurChairman_Detaildiv{
        padding: 10px;
        text-align: center;
    }
    .OurChairman_Detaildiv h1{
        font-size: 22px;
        margin-top: 20px;
    }
    
    .OurChairman_Detaildiv p{
        padding-top: 20px;
        letter-spacing: 1px;
        font-size: 17px;
    }
}