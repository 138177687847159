.topbgImgDiv {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.topbgImgDiv img {
    width: 100%;
    height: 100vh;
    object-fit: cover;
    filter: brightness(40%);

}

.topbgImgDiv .h1donate {
    font-weight: bold;
    font-size: 56px;
    font-style: italic;
    letter-spacing: 3px;
    margin-top: -25%;
    margin-bottom: 20%;
    color: white;
    filter: brightness(100%);

}

.leftdiv div {
    display: flex;
}

.leftdiv div p {
    text-align: justify;
    letter-spacing: 1px;
    font-size: 17px;
}

.leftdiv div p .icon {
    color: #3aa348;
    margin-right: 8px;
}

.rightdiv .ultag {
    display: flex;
    /* justify-content: space-between; */
    align-items: center;
    list-style: none;
    padding-left: 0;
}

.rightdiv .ultag li {
    border: 3px solid gray;
    width: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px;
    border-radius: 4px;
    font-weight: bold;
    margin-right: 10px;
    cursor: pointer;
}

.rightdiv .ultag li.active {
    background-color: #3aa348;
    color: white;
    border: 3px solid #3aa348;

}

.rightdiv .ultag li input {
    width: 80px;
}

.bank_deatials {
    margin: 50px;
    border: 1px solid lightgray;
    border-radius: 4px;
}

.bank_deatials .tophead {
    text-align: center;
    background-color: #3aa348;
    color: white;
    padding: 20px;
    font-size: 18px;
    margin-bottom: 0;
}

.bank_deatials .h6tag {
    padding: 20px;
}

.bank_deatials .bankinfo {
    padding: 20px;

}

.bank_deatials .bankinfo p {
    margin-bottom: 10px;
}

.bank_deatials .bankinfo p strong {
    color: rgb(82, 79, 79);
}

.bottomdiv {
    margin: 50px;
    font-size: 18px;

}

.bottomdiv h5 {
    display: flex;
    align-items: center;
    font-weight: bold;
    color: rgb(82, 79, 79);

}

.bottomdiv ul li {
    font-size: 18px;
}

.bottomdiv h5 .icon {
    color: #3aa348;
    margin-right: 8px;
}

.bottomdiv h6 {
    font-weight: bold;
    color: rgb(82, 79, 79);

}

@media only screen and (max-width: 767px) {
    .topbgImgDiv img {
        height: 250px;
    }

    .topbgImgDiv .h1donate {
        font-size: 30px;
        margin-top: -35%;
        margin-bottom: 25%;
    }
    .rightdiv .h4tag{
        margin-left: 20px;
        font-size: 18px;
    }

    .rightdiv .ultag {
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
    }

    .rightdiv .ultag li {
        margin: 10px;
    }
    .bank_deatials {
        margin: 50px 10px;
    }
    .bottomdiv {
        margin: 50px 15px;
        font-size: 16px;
    
    }

    .bottomdiv h5 {
        align-items:unset;
        font-size: 16px;
    
    }
    .bottomdiv h5 .icon {
        font-size: 40px !important;
    }
    .bottomdiv ul li {
        font-size: 16px;
    }
    
   
}