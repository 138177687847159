.marxgroup-topdivbar {
    background-color: #000000;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding: 5px 0px 0px 5px;
    color: white;
}

.marxgroup-audioplayer {
    width: 100px;
    height: 28px;
    margin: 1px 5px 0 1px;
    color: rgb(51, 51, 190);
}

.marxgroup-audioplayer:hover {
    width: 200px;
}

.marxgroup-navbar {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    background-color: black;
    color: white;
    width: 100% !important;
    padding-top: 0;
}

.marxgroup-navbar .marxgroup-nav-baar {
    display: flex;
    flex-wrap: wrap;
    padding: 0 0 10px 0;
    text-align: center;
    align-items: center;
    width: 100% !important;
}

.marxgroup-navbar .marxgroup-nav-baar .logo a img {
    margin: 0px 20px;
    width: 110px;
    height: 110px;
    object-fit: contain;

}

.companyprofilename h1 {
    font-weight: bolder;
    letter-spacing: 2px !important;
    font-size: 45px !important;
    text-align: center;
    text-transform: uppercase;
    color: red;
    text-shadow: 2px 2px 15px skyblue, 2px 2px 15px skyblue;
    filter: brightness(170%);
}

.marx-group-navlist {
    margin: 1px 1px 0 1px;
}

.marx-group-navlist ul {
    display: flex;
    list-style: none;
    justify-content: space-around;
    align-items: center;
    margin: auto;
    padding-left: 1px !important;
}

.marx-group-navlist ul li {
    margin: 0 2px;
}

.marx-group-navlist ul li a {
    display: block;
    text-decoration: none;
    font-size: 16px !important;
    color: black;
}

.marx-group-navlist ul li a .btnn1 {
    background-color: rgb(21, 87, 210);
    color: white;
    border: 1px solid white;
    padding: 4px 10px;
    font-weight: 500;
}

.marx-group-navlist ul li a .btnn2 {
    background-color: rgb(255, 255, 255);
    color: rgb(0, 0, 0);
    padding-left: 0;
    margin: 0;
}

.marx-group-navlist ul li {
    position: relative;
}

.boxvisible {
    display: block;
    position: absolute;
    background-color: white;
    color: black;
    margin-left: -110px;
    margin-top: 8px;
    width: 160px;
    padding: 10px 15px;
    z-index: 99;
    border-radius: 6px;
    /* box-shadow: 2px 2px 4px 4px rgb(255, 255, 255), 2px 2px 4px 4px rgb(255, 255, 255); */
}

.boxhidden {
    display: none;
}

.boxvisible a {
    display: flex;
    align-items: center;
    margin: 0 0 8px 0;
}

/* .marx-group-navlist ul li:hover .profileboxhidden{
    display: block;
} */

@media screen and (max-width:767px) {
    .marxgroup-topdivbar {
        width: 100%;
        display: flex !important;
        justify-content: flex-end;
        align-items: center !important;
        padding: 5px 5px 10px 5px;
    }

    .marx-group-navlist {
        margin-top: 0px;
    }

    .marx-group-navlist ul {
        justify-content: unset;
    }

    .marx-group-navlist ul li a .btnn1{
        padding: 2px 6px;
        font-weight: 500;
    }
    .marx-group-navlist ul li a .btnn2 {
        padding: 0;
        margin: 0;
    }
    .marxgroup-navbar .marxgroup-nav-baar .logo a img {
        margin-left: 25px;
        margin-bottom: 0px;
        width: 80px;
        height: 80px;
        object-fit: contain;
    
    }

    .companyprofilename h1 {
        width: 100%;
        font-size: 18px !important;
        letter-spacing: 1px !important;
        margin: 10px 0 !important;
        text-align: center;
    }

    .profileboxvisible {
        margin-left: -120px;
        margin-top: 3px;
        min-width: 320px;
        width: 100%;
        padding: 5px;
        box-shadow: unset;
    }

    .textarea {
        width: 100%;
        padding: 2px;
    }
}

@media screen and (min-width: 768px) and (max-width: 992px) { 
    .companyprofilename h1 {
        font-size: 30px !important;
        text-align: center;
        text-transform: uppercase;
        color: red;
        text-shadow: 2px 2px 15px skyblue, 2px 2px 15px skyblue;
        filter: brightness(170%);
    }
}