.mainbox-service{
    background-color:black;
    padding-top: 20px;
}
.mainbox-service h1{
    font-weight: bold;
    color: white;
    text-align: center;
    margin:0 7% 20px 7%;
    letter-spacing: 3px;
    border-bottom: 1px solid lightgray;
    padding-bottom: 20px;
}
.all-service{
    display: flex;
    justify-content: space-evenly;
    flex-wrap: wrap;
}
.the-services{
    width: 500px;
    text-align: center;
    margin: 30px 20px;
    text-align: center;
    border: 1px solid lightgrey;
    border-radius: 6px;
    padding: 20px 40px;
    background-color: rgb(0, 0, 0);
    color: white;
    box-shadow: 0 0 20px rgba(0, 0, 0, .1);
    transition: all 0.5s;
    overflow: hidden;
}
.the-services:hover{
    box-shadow: 0px 0px 20px lightblue,0px 0px 20px lightblue;
    transform: translate(0,8px);
}

.the-services img{
    width: 400px;
    height: 200px;
    object-fit: contain;
    border-radius: 4px;
    color: rgb(255, 255, 255);
    transition: all 0.5s;

}
.the-services img:hover{
    transform: scale(1.1);

}
.the-services p{
    text-align: start;
    letter-spacing: 1px;
}
@media screen and (max-width:767px) {
    .the-services{
        width: 100%;
        margin: 30px 10px;
        padding: 20px 20px;
    }
    .the-services img{
        width: 100%;
    }
}

@media screen and (min-width: 768px) and (max-width: 992px) { 
    .the-services{
        width: 80%;
        margin: 30px 10px;
        padding: 20px 20px;
    }
    .the-services img{
        width: 100%;
    }
}