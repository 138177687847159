.fantasy {
    display: flex;
    justify-content: space-around;
    margin: 20px;
}

.fantasy .textfantasy {
    padding: 20px;
}

.fantasy .textfantasy .small_logo_div {
    display: flex;
    justify-content: center;
}

.fantasy .textfantasy .small_logo_div img {
    width: 65px;
    border-radius: 6px;
    margin-bottom: 10px;

}

.fantasy .textfantasy h5 {
    font-size: 24px;
    font-weight: bold;
    color: #4db5ff;
    margin-bottom: 20px;
    letter-spacing: 2px;

}

.fantasy .textfantasy h6 {
    font-size: 16px;
    font-weight: bold;
    color: #56594d;
    margin-top: 50px;
    margin-bottom: 10px;
}

.fantasy .textfantasy h1 {
    font-size: 56px;
    font-weight: bold;
    color: #56594d;
}

.fantasy .textfantasy p {
    font-size: 17px;
    margin-bottom: 30px;
    letter-spacing: 1px;
    color: rgb(67, 64, 64);
    padding-top: 40px;

}

.fantasy .textfantasy button {
    background-color: white;
    border: 1px solid #4db5ff;
    color: #4db5ff;
    padding: 10px 30px;
    font-weight: bold;
    letter-spacing: 2px;
    font-size: 22px;
    margin: 20px 0;
}

.fantasy .textfantasy button:hover {
    background-color: #4db5ff;
    color: white;
}

.fantasy div {
    padding: 20px;

}

.fantasy .imggdiv img {
    border-radius: 6px;
    width: 100%;
    height: 600px;
    object-fit: cover;
}

.secondbgcolor {
    background-color: #e6f1f9;
    margin: 20px 0;
}

.PlotsHouse_heading {
    background-color: #4db5ff;
    padding: 20px;
    color: white;

}
.divhouselocation{
    display: flex;
    flex-wrap: wrap;
}
.linktag{
    text-decoration: none;
}

.PlotsHouse_area {
    background-color: #4db5ff;
    width: 300px;
    margin: 20px;
    border-radius: 6px;
    text-align: center;
}

.PlotsHouse_area div img {
    width: 270px;
    height: 200px;
    object-fit: cover;
    border-radius: 3%;
    margin: 20px 0;

}

.PlotsHouse_area div h5 {
    font-size: 24px;
    font-weight: bold;
    padding-bottom: 20px;
    color: white;
}

.Why_choose_us {
    background-color: #e6f1f9;
    margin: 20px 0;
}

.Why_choose_us h2 {
    font-size: 52px;
    font-weight: bold;
    padding: 20px 0 20px 30px;
    color: #56594d;

}

.Why_choose_us .chooseDiv {
    width: 300px;
    margin: 20px;
    text-align: center;

}

.Why_choose_us .chooseDiv div img {
    width: 130px;
    height: 130px;
    object-fit: contain;
    border-radius: 3%;
    margin: 20px 0;

}

.Why_choose_us .chooseDiv div .imggg {
    width: 190px;
    object-fit: contain;
    border-radius: 3%;
    margin: 20px 0;

}

.Why_choose_us .chooseDiv .textdiv {
    border-top: 3px solid #56594d;
    padding-top: 50px;
    margin-top: 50px;

}

.Why_choose_us .chooseDiv div h5 {
    font-size: 25px;
    font-weight: bold;
    margin-bottom: 20px;
    color: #56594d;
}

/* testimonial */
#testimonials {
    /* background-color: #e6f1f9; */
    padding-bottom: 10px;
}

.testimonials_h2 {
    font-size: 52px;
    font-weight: bold;
    padding: 20px 0 40px 30px;
    color: #56594d;
}

.testimonials__container {
    width: 100%;
    padding-bottom: 1rem;
}

/* .testimonials__container .swiper-wrapper{
    width: 100%;
  }
  .testimonials__container .swiper-wrapper .swiper-slide{
    width: 50% !important;
    margin: 0 5px;
  } */

.client__avatar {
    width: 6rem;
    aspect-ratio: 1/1;
    overflow: hidden;
    border-radius: 50%;
    margin: 0 auto 1rem;
    border: 0.4rem solid rgba(77, 181, 255, 0.4);
  }
.testimonial {
    background: #e6f1f9;
    text-align: center;
    padding: 2rem;
    border-radius: 2rem;
    user-select: none;
    height: 300px !important;
    margin: 30px 0;
}

.client__name {
    font-size: 24px;
    font-weight: bold;
    margin-top: 30px;
}

.client__review {
    color: #000000;
    display: block;
    width: 80%;
    font-size: 16px;
    margin: auto;
}


/* end testimonial */
@media screen and (max-width:767px) {
    .fantasy {
        display: block;
        margin: 5px;
    }

    .fantasy .textfantasy {
        padding: 20px;
    }

    .fantasy .textfantasy .small_logo_div {
        display: flex;
        justify-content: center;
    }

    .fantasy .textfantasy .small_logo_div img {
        width: 70px;
    }

    .fantasy .textfantasy h5 {
        font-size: 20px;
        text-align: center;

    }

    .fantasy .textfantasy h1 {
        font-size: 35px;
    }

    .fantasy .textfantasy button {
        color: white;
        border: 1px solid #4db5ff;
        background-color: #4db5ff;
        padding: 10px 30px;
        font-weight: bold;
        letter-spacing: 2px;
        font-size: 22px;
        margin: 20px 0;
    }
    
    .fantasy .textfantasy button:hover {
        background-color: #4db5ff;
        color: white;
    }

    .fantasy .imggdiv img {
        height: 300px;
    }

    .PlotsHouse_heading {
        padding:10px;
    }
    .PlotsHouse_heading h3{
     font-size: 20px;
    }
   

    .Why_choose_us h2 {
        font-size: 30px;
    
    }

    .testimonials_h2 {
        font-size: 30px;
    }
    .testimonials__container {
        width: 90%;
        padding-bottom: 1rem;
    }


    .fantasy .textfantasy p {
        font-size: 17px;
        margin-bottom: 30px;
        letter-spacing: 1px;
        color: rgb(67, 64, 64);
        padding-top: 40px;

    }

}