.Disclaimer{
    margin: 50px;
}
.Disclaimer h6{
    font-weight: bold;
    letter-spacing: 0.7px;
    margin: 12px 0;
}
.Disclaimer ul{
    padding-left: 18px;
}
.Disclaimer ul li{
    font-size: 17px;
    letter-spacing: 0.7px;
    margin: 10px 0;
}
.Disclaimer p{
    letter-spacing: 0.7px;
    margin: 10px 0;
    font-size: 17px;

}