

.NGO_topHeader {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: white;
    backdrop-filter: blur(15px);
    box-shadow: 0 0 2px white;
    padding: 0.4rem;
    position: sticky !important;
    top: 0 !important;
    z-index: 9999;
    color: #3aa348;

}

.NGO_topHeader .logo {
    width: 3.5rem;
    margin-left: 34px;
    /* border-radius: 0.8rem; */
    border-radius: 0.2rem;
}

.NGO_topHeader a {
    text-decoration: none;
    display: flex;
    align-items: center;

}

.NGO_topHeader .ngo_compnyName {
    font-weight: bold;
    font-size: 24px;
    text-transform: uppercase;
    margin-left: 10px;
    color: #3aa348;
}

.NGO_topHeader .ngo_liauto {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    list-style: none;
    margin: 0;
}

.NGO_topHeader .ngo_liauto li {
    margin: 0 .4rem;
    font-weight: 500;
}

.NGO_topHeader .ngo_liauto li a {
    padding: 0.5rem 0.4rem;
    border-radius: 6px;
    text-decoration: none;
    color: #3aa348;
}


.NGO_topHeader .ngo_liauto li a:hover {
    background: #c2e7c7;
    border-radius: 6px;
}

.NGO_topHeader .ngo_liauto li a.active {
    background: #3aa348;
    color: white;
    padding: 0.5rem 0.4rem;
    border-radius: 6px;

}
.NGO_topHeader .ngo_liauto li .dropdown .btn{
    color: #3aa348 !important;
    padding: 0.5rem 0.4rem;
    font-weight: 500;


}
.NGO_topHeader .ngo_liauto li .dropdown .btn:hover{
    background: #c2e7c7;
    border-radius: 6px;
}
.custom-dropdown-menu {
    color: #3aa348 !important;
}

.custom-dropdown-menu a {
    color: #3aa348 !important;
}

.dropdown-menu {
    color: #3aa348 !important;
}

.dropdown-menu .dropdown-item {
    color: #3aa348 !important;
}

.nested-dropdown-menu {
    /* Add your custom styles for the nested dropdown menu here */
    visibility: hidden;
    position: absolute;
    top: 0;
    left: 100%;
    /* Position it to the right of the parent dropdown */
}

.action:hover .nested-dropdown-menu {
    visibility: visible;
    position: absolute;
    margin-left: 99%;
    margin-top: -15%;
}

.action2:hover .nested-dropdown-menu {
    visibility: visible;
    position: absolute;
    margin-left: 99%;
    margin-top: 6%;

}

.NGO_topHeader .ngo_liauto li .buttonnn{
    background-color: #3aa348;
    color:  white;
    text-transform: capitalize;
}


@keyframes moveDown {
    from {
        transform: translateY(-5rem);
    }

    to {
        transform: translateY(0rem);
    }
}

@media only screen and (max-width: 767px) {
    .NGO_topHeader {
        z-index: 2;
        width: 100%;

    }

    .rightUL {
        display: none;
    }


    .NGO_topHeader .logo {
        width: 3.2rem;
        margin: 0 10px;
        border-radius: 0.5rem;
    }

    .NGO_topHeader .ngo_compnyName {
        display: none;
    }
}