
.OurDirectors_weworkIn {
    margin: 0px 0;
    background-color: rgb(235, 233, 233);
    color: black;
    padding: 40px 0;
}

.OurDirectors_alldetail {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin-right: 0;
}

.linktag_OurDirectors {
    text-decoration: none;
    /* border: 1px solid rgb(159, 154, 154); */
    box-shadow:  0 0.25rem 1.125rem rgba(75, 70, 92, 0.1);
    border-radius: 4px;
    padding: 0;
    width: 400px;
    background-color: white;
}


.OurDirectors_areadiv {
    width: 100%;
    margin: 30px 0px;
    border-radius: 10px;
}
.OurDirectors_areadiv .teaminfo{
    text-align: center;
}


.OurDirectors_areadiv div img {
    width: 100%;
    height: 320px;
    object-fit: contain;
    margin: 0px 0 20px 0;

}

.OurDirectors_areadiv div h6 {
    font-weight: 600;
    padding: 0 20px;
    color: black;
    font-size: 20px;
    margin: 0 0 10px 0;
    text-align: center;
}
.OurDirectors_areadiv div .position_director {
    /* border-top: 3px dashed black; */
    width: 200px;
    margin: 20px auto;
}

.OurDirectors_areadiv div p {
    padding: 0 20px;
    color: black;
    font-size: 15px;
    text-align: center;

}

@media only screen and (max-width: 767px) {
    .linktag_OurDirectors {
        width: 90%;
    
    }
}