.maqworld_topdiv {
  margin-bottom: 18%;
}

.maqworld_topdiv img {
  width: 100%;
  height: 90vh;
  object-fit: cover;
  margin-bottom: 0px;
  filter: brightness(40%);
}

.maqworld_topdiv .company_head {
  color: white;
  margin-top: -25%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  filter: brightness(100%);

}

.maqworld_topdiv .company_head h1 {
  font-weight: bold;
  font-size: 70px;
  letter-spacing: 2px;
}




.mainhome_welcomeDiv {
  text-align: center;
  padding: 20px 0;
  background-color: rgb(235, 233, 233);
  color: #1c1b1b;
}


.mainhome_home {
  display: flex;
  justify-content: space-around;
  margin: 20px 0;

}


.mainhome_home .imggdiv {
  padding: 10px 50px;

}

.mainhome_home .imggdiv img {
  border-radius: 6px;
  width: 100%;
  height: 600px;
  object-fit: cover;
}

.mainhome_home .textdiv {
  padding: 10px 50px;
  text-align: start;

}

.mainhome_home .textdiv h6 {
  font-size: 40px;
  font-weight: bold;
  /* border-bottom: 1px solid lightgray; */
  color: #1c1b1b;
  margin-top: 10px;
  margin-bottom: 10px;
  padding-bottom: 10px;
}

.mainhome_home .textdiv p {
  font-size: 17px;
  margin: 0px;
  letter-spacing: 1px;
  color: #1c1b1b;
  padding-top: 20px;
  text-align: justify;

}

/* .mainhome_home .textdiv .morebtn {
    background: linear-gradient(to right, #8c5fff, #f17bfe);
    color: white;
    text-transform: capitalize;
    font-size: 16px;
    padding: 8px 30px;
    margin-top: 40px;
} */

.homemaq_weworkIn {
  margin: 0px 0;
  background-color: rgb(235, 233, 233);
  color: #1c1b1b;
  padding: 40px 0;
}


.homemaq_alldetail {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-right: 0;
}

.homemaq_alldetail a {
  color: white;
}

.linktag_homemaq {
  text-decoration: none;
  border-radius: 3%;
  padding: 0;
  /* border: 3px solid lightgray; */
  width: auto;
  background-color: white;
  color: #1f1d1d;
}


.homemaq_areadiv {
  width: 100%;
  margin:20px 0px;
  text-align: center;
}

.homemaq_areadiv div img {
  width: 310px;
  height: 360px;
  object-fit: cover;
  border-radius: 2% 2% 0 0;
  margin: 0px 0 20px 0;
}

.homemaq_areadiv div h6 {
  font-weight: bold;
  padding: 0 20px;
  color: #1f1d1d;
  font-size: 24px;
  margin: 20px 0 10px 0;

}

.homemaq_areadiv div p {
  padding: 0 20px;
  color: #1f1d1d;
  font-size: 17px;
  font-weight: bold;

}



.ourbusiness_allproject_div {
  margin: 0px 0;
  /* background-color: #D2AB5F; */
  background-color: rgb(235, 233, 233);
  color: #1c1b1b;
  padding: 40px 0;
}

.ourbusiness_allproject_div h3 {
  font-size: 40px;
  font-weight: 600;
  margin: 40px 0;
  text-align: center;
}

.div_slide_ourbusiness_allproject {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.div_business_alldetail {
  margin: 20px;
  width: 380px;
  height: auto;
  border: 5px solid white;
  border-radius: 4px;
  background-color: white;
  padding-bottom: 0px;
  color: black;
  box-shadow: 0 0.25rem 1.125rem rgba(75, 70, 92, 0.1);

}

.div_business_alldetail img {
  height: 300px;
  width: 100%;
  margin: auto;
  object-fit: cover;
  border-radius: 4px 4px 0 0;
}

.div_business_alldetail h4 {
  margin: 20px 20px 0 20px;
  font-weight: 700;
  font-size: 18px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  text-transform: uppercase;
  height: 50px;
}

.div_business_alldetail .btnread {
  background: linear-gradient(to right, #553eb0, #7b3eb0);
  color: white;
  text-transform: capitalize;
  font-size: 16px;
  padding: 8px 30px;
  margin-top: 20px;
  width: 100%;
}


.flip-card {
  background-color: transparent;
  width: 400px;
  height: 400px;
  perspective: 1000px;
}

.flip-card-inner {
  position: relative;
  width: 100%;
  height: 100%;
  text-align: center;
  transition: transform 0.6s;
  transform-style: preserve-3d;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  border-radius: 20px;


}

.flip-card:hover .flip-card-inner {
  transform: rotateY(180deg);

}

.flip-card-front,
.flip-card-back {
  position: absolute;
  width: 100%;
  height: 100%;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  border-radius: 20px;

}

.flip-card-front {
  background-color: #bbb;
  color: black;
  border-radius: 20px;
  border: 5px solid white;

}

.flip-card-front img {
  width: 100%;
  height: 100%;
  border-radius: 20px;
  object-fit: cover;

}

.flip-card-back {
  background-color: white;
  border: 5px solid white;
  color: #1c1b1b;
  transform: rotateY(180deg);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 0 30px;

}


.flip-card-back h1 {
  font-weight: bold;
  font-size: 26px;
  text-transform: uppercase;
  margin-bottom: 30px;
}

.flip-card-back p {
  margin: 0 0 20px 0;
  font-size: 17px;
  font-weight: 500;
  text-align: justify;
  text-transform: capitalize;
}

.flip-card-back button {
  background-color: white;
  border: 2px solid #1c1b1b;
  color: #1c1b1b;
  padding: 10px 40px;
  font-weight: 600;
  text-transform: capitalize;
}

.flip-card-back button:hover {
  background: linear-gradient(to right, #553eb0, #7b3eb0);
  color: white;
  border: 2px solid #553eb0;

}


/* concern */


.concern-card {
  background-color: transparent;
  width: 300px;
  height: 300px;
  perspective: 1000px;
}

.concern-card-inner {
  position: relative;
  width: 100%;
  height: 100%;
  text-align: center;
  transition: transform 0.6s;
  transform-style: preserve-3d;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  border-radius: 20px;

}

.concern-card:hover .concern-card-inner {
  transform: rotateY(180deg);

}

.concern-card-front,
.concern-card-back {
  position: absolute;
  width: 100%;
  height: 100%;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  border-radius: 20px;

}

.concern-card-front {
  background-color: #bbb;
  color: black;
  border-radius: 20px;
  border: 5px solid white;

}

.concern-card-front img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 18px;
  object-fit: cover;

}

.concern-card-back {
  background-color: #1f1d1d;
  border: 5px solid white;
  color: white;
  transform: rotateY(180deg);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 0 30px;
}

.concern-card-back ul {
  padding: 0;
  list-style: square;
}

.concern-card-back ul li {
  margin: 5px 0;
  text-align: start;
}


.aboutcomp_Excellence {
  background-color: rgb(235, 233, 233);
  color: #1c1b1b;
  text-align: center;
  padding: 40px;
}

.maqhome_detailinfo {
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 240px;
  width: 290px;
  margin: 20px;
  background-color: white;
  color: #1c1b1b;
  padding: 0 20px;
  border-radius: 6px;
  box-shadow: 0 0.25rem 1.125rem rgba(75, 70, 92, 0.1);

}

.maqhome_detailinfo div {
  text-align: center;
}

.maqhome_detailinfo div h4 {
  margin: 10px 0;
  font-size: 42px;
  font-weight: bold;
  letter-spacing: 2px;
}

.maqhome_detailinfo div p {
  font-size: 16px;

}

.featured_projectweworkIn {
  margin: 0px 0;
  background-color: rgb(235, 233, 233);
  color: #1c1b1b;
  padding: 40px 0;
}

@media screen and (max-width:767px) {

  .maqworld_topdiv {
    margin-bottom: 24%;
  }

  .maqworld_topdiv img {
    height: 60vh;
  }

  .maqworld_topdiv .company_head {
    margin-top: -45%;

  }

  .maqworld_topdiv .company_head h1 {
    font-size: 30px;
    text-align: center;
  }

  .mainhome_home .imggdiv {
    padding: 10px 20px;

  }

  .mainhome_home .imggdiv img {
    height: 300px;
  }

  .mainhome_home .textdiv {
    padding: 10px 20px;
  }

  .mainhome_home .textdiv h6 {
    font-size: 30px;
    text-align: center;
  }

  .linktag_homemaq {
    border-radius: 2%;
    width: 90%;

  }

  .homemaq_areadiv {
    width: 100%;
    text-align: center;
  }

  .homemaq_areadiv div img {
    height: 300px;
    width: 90%;
    border-radius: 2% 2% 0 0;

  }

  .ourbusiness_allproject_div h3 {
    margin-top: 10px;
    padding-top: 30px;
    padding-bottom: 30px;
  }

  .div_business_alldetail {
    width: 90%;
  }

  .flip-card {
    background-color: transparent;
    width: 90%;
    height: 300px;
    margin: 20px;
  }


  .flip-card-back {
    width: 100%;
    height: 300px;
  }

  .flip-card-back h1 {
    font-size: 22px;
    margin-bottom: 10px;
  }

  .flip-card-back p {
    font-size: 14px;
  }

  .flip-card-back button {
    padding: 8px 30px;
  }


  .concern-card {
    width: 90%;
    height: 300px;
    margin: 20px;
  }

  .concern-card-back {
    width: 100%;
    height: 300px;
  }
  .aboutcomp_Excellence {
    padding: 10px;
  }
  .maqhome_detailinfo {
    height: 240px;
    width: 80%;
    margin: 20px auto;
    padding: 0 20px;
  
  }

}