.Hajjumrah_topdiv {
    margin: 0;
}

.Hajjumrah_topdiv img {
    width: 100%;
    height: 100vh;
    object-fit: cover;
    margin-bottom: 0px;
    filter: brightness(50%);
}

.Hajjumrah_topdiv .hajjumrah_head {
    color: white;
    margin-top: -15%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    filter: brightness(100%);
    padding:20px 10%;
    background-color: black;
    opacity: 0.6;
}

.Hajjumrah_topdiv .hajjumrah_head h1 {
    font-weight: bold;
    font-size: 60px;
    letter-spacing: 1px;
    text-transform: uppercase;
}

.Hajjumrah_topdiv .hajjumrah_head p {
    font-weight: bold;
    font-size: 20px;
    letter-spacing: 2px;
    text-align: center;
}
.Hajjumrah_topdiv .hajjumrah_head h5 {
    font-weight: bold;
    font-size: 22px;
    letter-spacing: 2px;
    text-align: center;
}

.quote-container {
    font-size: 17px;
    font-style: italic;
    text-align: center;
    transition: opacity 1s;
    opacity: 1;
    padding: 20px 70px;
    height: 90px;
    background-color: #edaeae;
  }
  
  .fade-in {
    opacity: 1;
  }
  
  .fade-out {
    opacity: 0.8;
  }

  
.PopularTours_div {
    background-color: #ecebc4;
    padding: 40px 5px;
    text-align: center;
}

.tour_slide {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}


.tour_slide a {
    text-decoration: none;
}

.populartour_Slider {
    margin: 20px;
    width: 280px;
    text-align: justify;
    background: linear-gradient(to right, #9da127, #93a715);
    padding-bottom: 0px;
    color: white;
    box-shadow: 0 0.25rem 1.125rem rgba(75, 70, 92, 0.1);
    border-radius: 8px;

}

.populartour_Slider img {
    height: 40vh;
    border: 5px solid white;
    width: 100%;
    object-fit: cover;
    margin: 0;
    
}

.populartour_Slider h4 {
    margin:0;
    font-weight: bold;
    font-size: 20px;
    text-align: center;
    padding: 10px 0;
    border-radius: 8px 8px 0 0;

}

.PopularTours_div .booknowbtn {
    background: linear-gradient(to right, #9da127, #93a715);
    color: white;
    text-transform: capitalize;
    font-size: 16px;
    padding: 8px 30px;
    margin-top: 0px;
    border-radius: 0 0 8px 8px;
}
.hotelforumrah{
    margin: 90px;
    height: 300px;
}
.LuxuriousHotel{
    letter-spacing: 1px;
    padding: 0 40px;
}
.LuxuriousHotel h1{
font-size: 24px;
font-weight: bold;

}
.LuxuriousHotel button{
    background: linear-gradient(to right, #2a003d, #7e15a7);
    color: white;
    text-transform: capitalize;
    font-size: 16px;
    padding: 8px 30px;
    margin-top: 0px;
    border-radius: 8px;
}

.hajjimgdiv img{
    width: 100%;
    height: 300px;
    border-radius: 6px;
    border: 5px solid #690e7d;
}

.clientreview_topimg{
    box-shadow:  0 0.25rem 1.125rem rgba(75, 70, 92, 0.1);
    border-radius: 4px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 20px;
    width: 200px !important;
    padding: 20px;
}

.clientreview_topimg img {
    height: 100px;
    width: 100%;
    object-fit: contain;
    margin: auto;
    align-items: center;
    border-radius: 4px;
}
.hajjharam img{
    width: 100%;
}

@media screen and (max-width:767px) {
    .Hajjumrah_topdiv img {
        height: 400px;
    }

    .Hajjumrah_topdiv .hajjumrah_head {
        margin-top: -62%;

    }

    .Hajjumrah_topdiv .hajjumrah_head h1 {
        font-size: 26px;
        text-align: center;
    }

    .Hajjumrah_topdiv .hajjumrah_head p {
        font-size: 10px;
        margin-top: 10px;
        padding: 0 10px;
    }

    .tour_slide {
        padding: 10px 10px;
        width: 100%;
    }

    .tour_slide h1 {
        font-size: 18px;
    }

    .populartour_Slider img {
        height: 250px;
        width: 100% !important;
        /* margin-left: 10%; */
    }
    .quote-container {
        padding: 20px;
        height: auto;
      }
      .hotelforumrah{
        margin: 10px;
        height: auto;
    }
    .hotelforumrah div{
        padding: 0px;
    }
    .LuxuriousHotel{
        padding:20px 10px;
    }
    .LuxuriousHotel h1{
    font-size: 24px;
    font-weight: bold;
    padding:20px 0;
    
    }
    .LuxuriousHotel button{
        margin: 20px 0;
    }
    .bbbeeeee{
        
        margin: 10px;
    }
    .clientreview_topimg{
        margin: 10px;
        width: 100% !important;
        padding: 10px;
    }
    

}