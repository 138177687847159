

.Restaurant_topHeader {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: white;
    backdrop-filter: blur(15px);
    box-shadow: 0 0 2px white;
    padding: 0.4rem;
    position: sticky !important;
    top: 0 !important;
    z-index: 9999;
    color: #282b29;

}

.Restaurant_topHeader .logo {
    width: 3.5rem;
    margin-left: 34px;
    /* border-radius: 0.8rem; */
    border-radius: 0.2rem;
}

.Restaurant_topHeader a {
    text-decoration: none;
    display: flex;
    align-items: center;

}

.Restaurant_topHeader .ngo_compnyName {
    font-weight: bold;
    font-size: 24px;
    text-transform: uppercase;
    margin-left: 10px;
    color: #282b29;
}

.Restaurant_topHeader .Restaurant_liauto {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    list-style: none;
    margin: 0;
}

.Restaurant_topHeader .Restaurant_liauto li {
    margin: 0 0.2rem;
    font-weight: 500;
    padding: 0.2rem;

}

.Restaurant_topHeader .Restaurant_liauto li a {
    border-radius: 6px;
    text-decoration: none;
    color: #282b29;

}


.Restaurant_topHeader .Restaurant_liauto li:hover {
    background: #e7c2c2;
    border-radius: 6px;
}


.Restaurant_topHeader .Restaurant_header_li {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    list-style: none;
    margin: 0;
}

.Restaurant_topHeader .Restaurant_header_li li {
    margin: 0 0.5rem;
    font-weight: 600;
    font-size: 19px;
    padding: 0.5rem 0.8rem;

}

.Restaurant_topHeader .Restaurant_header_li li a {
    border-radius: 6px;
    text-decoration: none;
    color: #282b29;

}


.Restaurant_topHeader .Restaurant_header_li li:hover {
    background: #e7c2c2;
    border-radius: 6px;
}

.NGO_topHeader .ngo_liauto li a.active {
    background: #a52121;
    color: white;
    padding: 0.5rem 0.4rem;
    border-radius: 6px;
}

@keyframes moveDown {
    from {
        transform: translateY(-5rem);
    }

    to {
        transform: translateY(0rem);
    }
}

@media only screen and (max-width: 767px) {
    .Restaurant_topHeader {
        z-index: 2;
        width: 100%;

    }

    .rightUL {
        display: none;
    }


    .Restaurant_topHeader .logo {
        width: 3.2rem;
        margin: 0 10px;
        border-radius: 0.5rem;
    }

    .Restaurant_topHeader .ngo_compnyName {
        display: none;
    }
}