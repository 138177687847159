.topdiv_Builders {
    margin-bottom: 12%;
}

.topdiv_Builders img {
    width: 100%;
    height: 90vh;
    object-fit: cover;
    margin-bottom: 0px;
    filter: brightness(40%);
}

.topdiv_Builders .Buildershead {
    color: white;
    margin-top: -25%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    filter: brightness(100%);

}

.topdiv_Builders .Buildershead h1 {
    font-weight: bold;
    text-transform: uppercase;
    text-align: center;
    font-size: 70px;
    letter-spacing: 2px;
}



.city_countrydiv {
    background-color: rgb(235, 233, 233);
    color: #1c1b1b;
    padding: 40px 5px;
    text-align: center;
}
.city_countrydiv h1{
    text-transform: uppercase;
}

.city_countrydiv_slide {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}


.city_countrydiv_slide a {
    text-decoration: none;
}


.city_countrydiv_life_Slider {
    margin: 20px;
    width: 550px;
    text-align: justify;
    border: 20px solid white;
    border-radius: 40px;
    background-color: white;
    color: #1c1b1b;
    /* padding: 20px; */
    box-shadow: 0 0.25rem 1.125rem rgba(75, 70, 92, 0.1);

}

.city_countrydiv_life_Slider img {
    height: 280px;
    width: 100%;
    object-fit: cover;
    border-radius: 19px 19px 0 0;


}

.city_countrydiv_life_Slider h4 {
    margin: 0;
    padding: 10px 0;
    font-weight: 700;
    font-size: 20px;
    text-align: center;
    text-transform: uppercase;

}

@media screen and (max-width:767px) {

    .topdiv_Builders {
        margin-bottom: 24%;
    }

    .topdiv_Builders img {
        height: 60vh;
    }

    .topdiv_Builders .Buildershead {
        margin-top: -45%;

    }

    .topdiv_Builders .Buildershead h1 {
        font-size: 30px;
        text-align: center;
    }

    .city_countrydiv_slide {
        padding: 10px 10px;
        width: 100%;
    }

    .city_countrydiv_slide h1 {
        font-size: 18px;
    }
    .city_countrydiv_life_Slider {
        margin: 20px 5px;
        width: 96%;
    
    }
    .city_countrydiv_life_Slider img {
        height: 250px;
        width: 100% !important;
        /* margin-left: 10%; */
    }
}