.Shipping_All_World_topdiv {
    margin-bottom: 15%;
}

.Shipping_All_World_topdiv img {
    width: 100%;
    height: 100vh;
    object-fit: cover;
    margin-bottom: 0px;
    filter: brightness(40%);
}

.Shipping_All_World_topdiv .Shipping_AllWorld_head {
    color: white;
    margin-top: -33%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    filter: brightness(100%);

}

.Shipping_All_World_topdiv .Shipping_AllWorld_head h1 {
    font-weight: bold;
    font-size: 70px;
    letter-spacing: 2px;
    text-transform: uppercase;
    text-align: center;
}

.Shipping_All_World_topdiv .Shipping_AllWorld_head p {
    font-weight: bold;
    font-size: 20px;
    letter-spacing: 2px;
    margin: 20px 10%;
    text-align: center;
}

.Shipping_AllWorld_customimg img{
    height: 32rem;
    width: 100%;
    filter: brightness(0.4);
    object-fit: cover;
}
.shippingservices_weworkIn {
    margin: 0px 0;
    background-color: rgb(41, 38, 30);
    color: white;
    padding: 40px 0;
}

.shippingservices_alldetail {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin-right: 0;
}

.shippingservices_alldetail a {
    color: white;
}

.linktag_shippingservices {
    text-decoration: none;
    border: 1px solid lightgray;
    border-radius: 3%;
    padding: 0;
    background-color: #fff;
}

.shippingservices_areadiv {
    width: 100%;
    margin: 20px 0px;
    border-radius: 4%;
    color: black;
}

.shippingservices_areadiv div img {
    width: 100%;
    border: 4px solid white;
    height: 280px;
    object-fit: cover;
    border-radius: 4% 4% 0 0;
    margin: -20px 0 20px 0;

}

.shippingservices_areadiv div h6 {
    font-weight: 600;
    padding: 0 20px;
    font-size: 24px;
    margin: 0 0 10px 0;

}

.shippingservices_areadiv div p {
    padding: 0 20px;
    font-size: 17px;

}

.subtle_Shipmentdiv {
    padding: 20px;
    background-color: #fff;
    border-radius: 6px;
    width: 390px;
    margin: 30px;
}

.subtle_Shipmentdiv .divtag {
    padding: 20px 20px 0 20px;
}

.Shipmentdiv {
    display: flex;
    justify-content: space-between;
    align-items: center;

}

.Shipmentdiv div {
    display: flex;
    align-items: center;
}

.Shipmentdiv div h3 {
    font-weight: bold;
    margin: 0 0 0 10px;
    font-size: 22px;

}

.Shipmentdiv img {
    width: 40px;
    height: 40px;
}
.Shipmentdiv .rightarrowimg{
    width: 20px;
    height: 20px;
}

.fddddddd {
    border: 1px solid rgb(243, 238, 238);
    box-shadow: 0 0.25rem 1.125rem rgba(75, 70, 92, 0.1);
    border-radius: 4px;
    margin: 0 10px;
    width: 400px;
    padding: 20px 0;
}

.fddddddd:hover {
    background-color: #e0c428;
    color: white;
}

.fddddddd .readbtnbox {
    font-size: 16px;
    font-weight: 500;
    padding: 8px 30px;
    margin: 10px 0 0 50px;
    text-transform: capitalize;
    background-color: #4171d2;
    color: white;
}

.fddddddd .readbtnbox:hover {
    background-color: #4171d2;
    color: white;
}

@media screen and (max-width:767px) {
    
    .Shipping_All_World_topdiv img {
        height: 450px;
    }

    .Shipping_All_World_topdiv .Shipping_AllWorld_head {
        margin-top: -73%;

    }

    .Shipping_All_World_topdiv .Shipping_AllWorld_head h1 {
        font-size: 26px;
    }

    .Shipping_All_World_topdiv .Shipping_AllWorld_head p {
        font-size: 10px;
        margin: 10px;
        padding: 0 10px;
    }
    .Shipping_AllWorld_customimg img {
        width: 100%;
        height: 20rem;
        margin: 0;
    }

    .linktag_shippingservices {
        width: 85%;
    }
    .subtle_Shipmentdiv {
        padding: 20px;
        background-color: #fff;
        border-radius: 6px;
        width: 86%;
        margin: 10px;
    }

}