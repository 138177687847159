.slider_maintop {
    /* padding: 10px 5px; */
    padding: 0;

}

.slider_maintop h1 {
    font-size: 24px;
    text-align: start;
    text-transform: uppercase;
}

.itemall_imgdiv img {
    height: 300px;
    width: 100%;
    object-fit: cover;
    border-radius: 4px;

}

.itemall_imgdiv p {
    margin-top: -50px;
    width: 300px;
    font-size: 19px;
    padding: 10px 20px;
    border-radius: 4px;
    background: #121111;
    color: white;
    opacity: 0.6;
}

@media screen and (min-width: 768px) and (max-width: 992px) {
    .slider_maintop {
        padding: 10px 0px;

    }

    .itemall_imgdiv img {
        height: 250px;
        width: 200px;
        object-fit: contain;
        border-radius: 4px;
    }
}

@media screen and (max-width:767px) {
    .slider_maintop {
        padding: 10px 10px;
        width: 100%;
    }

    .slider_maintop h1 {
        font-size: 18px;
    }

    .itemall_imgdiv img {
        height: 250px;
        width: 100% !important;
        margin: 0px;
    }
    .itemall_imgdiv p {
        width: 90%;
        margin-left: 10px;
        font-size: 16px;
    }
   
}