.maqcontact_topdiv {
    margin-bottom: 13%;
}

.maqcontact_topdiv img {
    width: 100%;
    height: 70vh;
    object-fit: cover;
    margin-bottom: 0px;
    filter: brightness(40%);
}

.maqcontact_topdiv .contact_maqhead {
    color: white;
    margin-top: -20%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    filter: brightness(100%);

}

.maqcontact_topdiv .contact_maqhead h1 {
    font-weight: bold;
    font-size: 70px;
    letter-spacing: 2px;
}


.contactus_divmaq {
    text-align: center;
    width: 800px;
    margin: auto;
    padding: 50px 0;
}

.contactus_divmaq h1 {
    font-size: 40px;
    font-weight: 600;
    margin-bottom: 40px;
}


.contactdiv_maqmain {
    display: flex;
    /* align-items: center; */
    margin: 20px 0;

}

.contactdiv_maqmain .ngo_icondiv {
    margin-right: 15px;
}

.contactdiv_maqmain .ngo_icondiv p {
    background-color: #5c4dff;
    color: white;
    border-radius: 50%;
    margin-bottom: 0;
}

.contactdiv_maqmain h6 {
    margin: 0;
    font-weight: bold;
    font-size: 18px;
}

.contactdiv_maqmain p {
    margin: 0;
    font-size: 18px;

}

.maqformdiv div input {
    height: 50px;
}



.Submitbtnbtn {
    background: linear-gradient(to right, #553eb0,#7b3eb0);
    color: white;
    text-transform: capitalize;
    font-size: 18px;
    letter-spacing: 1px;
    padding: 8px 60px;
    margin: 20px 0;
    border-radius: 6px;
    border: none;
}

.mainmaq_TouchContact {
    background-color: #fff;
    border-radius: 6px;
    padding: 50px;
    margin-right: 40px;
}

.maqcontactTouch {
    padding: 50px;
    background-color: rgb(235, 233, 233);
    color: #1c1b1b;
}

@media screen and (max-width:767px) {
    .maqcontact_topdiv img {
        height: 250px;
    }

    .maqcontact_topdiv .contact_maqhead {
        margin-top: -42%;

    }

    .maqcontact_topdiv .contact_maqhead h1 {
        font-size: 26px;
    }

    .maqcontactTouch {
        padding: 50px 10px;
    }

    .contactus_divmaq {
        width: 100%;
        padding: 40px 0;
    }

    .contactus_divmaq h1 {
        font-size: 30px;
        margin-bottom: 0px;
    }

    .maqformdiv {
        padding: 0;
        width: 90%;
        margin-left: 10px;
    }

    .maqformdiv div {
        padding: 0;
    }

    .mainmaq_TouchContact {
        padding: 50px 20px;
        margin: auto;
        width: 96%;
    }

}