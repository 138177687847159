.vissionmission_volunteer{
    display: flex;
    justify-content: space-between;
    margin:20px 10px 20px 0;
}
.vissionmission_volunteer div img{
    width: 100px;
    height: 60px;
    object-fit: cover;
    border-radius: 4px;
    margin: 0 20px;
}
.vissionmission_volunteer .text_vision h6{
    font-weight: bold;
    font-size: 18px;
    margin: 3px 0;
}
.vissionmission_volunteer .text_vision p{
    font-size: 16px;
    margin-bottom: 2px;
    padding-top: 0;
}

.ourprojectgreen{
    background-color: rgb(42, 112, 165) !important;
    padding: 50px 0;
}

.greenbgimg img{
    background-color: #fff;
    width: 120px;
    height: 120px;
    object-fit: contain;
    border-radius: 50%;
    padding: 20px;
}

.bgPortfolio{
    border: 1px solid rgb(243, 238, 238);
    box-shadow:  0 0.25rem 1.125rem rgba(75, 70, 92, 0.1);
    border-radius: 4px;
    margin:20px;
    width: 390px;
}


.team_green {
    padding: 10px 5px;
}

.team_green .team_green_detail{
    display: flex;
    padding: 20px;
}
.team_green .team_green_detail div{
    text-align: justify;
}

.team_green_imgdiv{
    padding: 0 30px;
}
.team_green_imgdiv img {
    width: 200px;
    height: 200px;
    object-fit: cover;
    border-radius: 50%;
}
.team_green .team_green_detail div a{
    text-decoration: none;
    color: rgb(34, 30, 30);

}
.team_green .team_green_detail div h4 {
    margin: 0px 0 10px 0;
}
.LatestUpdateslay{
    padding: 40px;
    margin-right: 0;
}
.LatestUpdateslay div h6{
    font-size: 26px;
    font-weight: bold;
}
.corevalues_imgdiv img{
    width: 100%;
}
.LatestUpdatesDiv{
    height: 400px;
    overflow-y: scroll;
    padding: 20px;
}
.LatestUpdatesDiv li{
    margin: 10px 0;
    font-size: 14px;
}

@media screen and (max-width:767px) {
    .team_green .team_green_detail{
        display: block;
        padding: 20px;
    }
}