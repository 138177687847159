
.NGOTrust_blog {
    display: flex;
    justify-content: space-around;
    margin: 20px 0;
}


.NGOTrust_blog .ngo_imggdiv {
    padding: 50px;

}

.NGOTrust_blog .ngo_imggdiv img {
    border-radius: 6px;
    width: 100%;
    height: 400px;
    object-fit: contain;
    box-shadow:  0 0.25rem 1.125rem rgba(75, 70, 92, 0.1);

}

.NGOTrust_blog .NGO_textdiv {
    padding: 50px;

}


.NGOTrust_blog .NGO_textdiv h6 {
    font-size: 22px;
    font-weight: bold;
    margin-top: 30px;
    margin-bottom: 10px;
}



.NGOTrust_blog .NGO_textdiv p {
    font-size: 17px;
    margin-bottom: 20px;
    letter-spacing: 1px;
    padding-top: 20px;
    text-align: justify;

}